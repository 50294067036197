define('gs1-frontend/pods/member-registration/step-4/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({
		kpMode: '',

		kpModeObserver: Ember.observer('kpMode', function () {
			this.set('model.member.numberRange', null);
			this.set('model.member.tmpCpTransferCode', null);
		})
	});
});