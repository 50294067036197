define('gs1-frontend/pods/member-registration/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return {
        member: this.get('store').createRecord('member', {
          memberType: 1,
          bankAccounts: []
        }),
        errors: []
      };
    },


    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        Ember.$('body').addClass('registration-page');
      },
      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        Ember.$('body').removeClass('registration-page');
      }
    }
  });
});