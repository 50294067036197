define('gs1-frontend/mixins/pageable-route-controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Mixin = Ember.Mixin;
	exports.default = Mixin.create({
		queryParams: [{
			page: {
				type: 'number'
			}
		}],

		page: 1
	});
});