define("gs1-frontend/pods/members/index/controller", ["exports", "gs1-frontend/mixins/pageable-route-controller"], function (exports, _pageableRouteController) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend(_pageableRouteController.default, {

		queryParams: [{
			term: {
				type: "string"
			}
		}, {
			activityCode: {
				type: "string"
			}
		}, {
			status: {
				type: "string"
			}
		}],

		allActivityCodes: Ember.computed(function () {
			return this.get("store").findAll("activity-code");
		}),

		termBufferObserver: Ember.observer("termBuffer", function () {
			var _this = this;

			clearTimeout(this.get("bufferTimeout"));
			this.set("bufferTimeout", setTimeout(function () {
				_this.refresh();
			}, 2000));
		}),

		// Refresh the model based on the current data
		refresh: function refresh() {
			var clear = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;


			// Set the search parameters
			this.set("term", clear ? null : this.get("termBuffer"));
			this.set("page", 1);

			// Reset timers
			clearTimeout(this.get("bufferTimeout"));
		},

		actions: {
			refresh: function refresh() {
				this.refresh();
			},
			clear: function clear() {
				this.refresh(true);
			}
		}

	});
});