define('gs1-frontend/pods/components/ui/-table/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		classNameBindings: ['renderingTable'],

		renderingTable: Ember.computed('items', function () {
			return this.get('items') && this.get('items.length') > 0 ? 'table-responsive' : 'empty-table';
		})
	});
});