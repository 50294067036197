define("gs1-frontend/pods/components/ui/-image/component", ["exports", "gs1-frontend/config/environment"], function (exports, _environment) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		tagName: "img",

		classNames: ["gs1-image"],

		attributeBindings: ["src", "alt"],

		defaultUrl: "/assets/img/no-image.png",
		plugins: ["Resize\\Pad", "Resize\\Match", "Resize\\Center", "Resize\\Scale"],

		src: Ember.computed("url", "mode", "width", "height", function () {
			var output = "";

			var _getProperties = this.getProperties("url", "mode", "width", "height"),
			    url = _getProperties.url,
			    mode = _getProperties.mode,
			    width = _getProperties.width,
			    height = _getProperties.height;

			if (!url) {
				url = this.get("defaultUrl");
			}
			output += _environment.default.gs1.serverEndpoint + "/" + _environment.default.gs1.imageEndpoint + "/?" + "url=" + url;

			if (mode && this.get("plugins").includes(mode) && width && height) {
				output += "&plugin[]=" + mode + "," + width + "," + height;
			}

			return !url.match(/^(https?:)?\/\//) ? _environment.default.gs1.serverEndpoint + url : url;
			// return Ember.String.htmlSafe(output);
		})
	});
});