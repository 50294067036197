define('gs1-frontend/pods/components/gtins/-view-gtin/component', ['exports', 'gs1-frontend/pods/components/modals/-modal/component'], function (exports, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend({

		id: 'modalViewGtin'

	});
});