define('gs1-frontend/models/company-prefix', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		prefixType: _emberData.default.attr('string'),
		prefixLength: _emberData.default.attr('number'),
		prefixValue: _emberData.default.attr('string'),
		gtinCount: _emberData.default.attr('number'),
		range: _emberData.default.attr('number'),

		// Relationships
		member: _emberData.default.belongsTo('member'),
		gtins: _emberData.default.hasMany('gtin')
	});
});