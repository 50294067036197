define('gs1-frontend/pods/application/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPISerializer.extend({
		keyForAttribute: function keyForAttribute(attr) {
			return Ember.String.underscore(attr);
		},
		keyForRelationship: function keyForRelationship(attr) {
			return Ember.String.underscore(attr);
		},
		serializeAttribute: function serializeAttribute(snapshot, json, key) {
			this._super.apply(this, arguments);
			/*
   if (snapshot.attr(key) != null) {
   	this._super(...arguments);
   }
   */
		}
	});
});