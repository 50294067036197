define('gs1-frontend/pods/members/single/company-prefixes/single/gtins/index/route', ['exports', 'gs1-frontend/config/environment', 'gs1-frontend/mixins/eventable', 'ember-simple-auth/mixins/authenticated-route-mixin', 'gs1-frontend/mixins/pageable-route'], function (exports, _environment, _eventable, _authenticatedRouteMixin, _pageableRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_pageableRoute.default, _authenticatedRouteMixin.default, _eventable.default, {

		queryParams: {
			status: {
				refreshModel: true,
				replace: true
			},
			term: {
				refreshModel: true,
				replace: true
			},
			classificationSegment: {
				refreshModel: true,
				replace: true
			},
			classificationFamily: {
				refreshModel: true,
				replace: true
			},
			classificationClass: {
				refreshModel: true,
				replace: true
			},
			classificationBrick: {
				refreshModel: true,
				replace: true
			}
		},

		i18n: Ember.inject.service(),

		getRidOfCurrentGtin: function getRidOfCurrentGtin() {
			var currentGtin = this.get('controller.currentGtin');
			if (currentGtin) {
				if (!currentGtin.get('id')) {
					currentGtin.destroyRecord();
				} else {
					currentGtin.rollbackAttributes();
				}
				this.set('controller.currentGtin', null);
			}
		},
		model: function model(params) {
			var options = {
				filter: {
					company_prefix: this.modelFor('members.single.company-prefixes.single').id
				}
			};

			if (!Ember.isEmpty(params.status)) {
				if (options.filter) {
					options.filter.status = params.status;
				} else {
					options.filter = {
						status: params.status
					};
				}
			}

			if (!Ember.isEmpty(params.term)) {
				if (options.filter) {
					options.filter.term = params.term;
				} else {
					options.filter = {
						term: params.term
					};
				}
			}

			if (!Ember.isEmpty(params.classificationSegment)) {
				if (options.filter) {
					options.filter.classification_segment_id = params.classificationSegment;
				} else {
					options.filter = {
						classification_segment_id: params.classificationSegment
					};
				}
			}

			if (!Ember.isEmpty(params.classificationFamily)) {
				if (options.filter) {
					options.filter.classification_family_id = params.classificationFamily;
				} else {
					options.filter = {
						classification_family_id: params.classificationFamily
					};
				}
			}

			if (!Ember.isEmpty(params.classificationClass)) {
				if (options.filter) {
					options.filter.classification_class_id = params.classificationClass;
				} else {
					options.filter = {
						classification_class_id: params.classificationClass
					};
				}
			}

			if (!Ember.isEmpty(params.classificationBrick)) {
				if (options.filter) {
					options.filter.classification_brick_id = params.classificationBrick;
				} else {
					options.filter = {
						classification_brick_id: params.classificationBrick
					};
				}
			}

			return this.pageableModel('gtin', 'gtins', options, params);
		},
		getNextAvailableGtin: function getNextAvailableGtin(controller, companyPrefix) {
			var self = this;

			Ember.$.ajax({
				type: 'GET',
				url: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/ajax/available' + '/' + companyPrefix,
				headers: {
					'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
				},
				success: function success(response) {
					if (response.next_available_gtin) {
						controller.set('nextAvailableGtin', response.next_available_gtin);
					} else {
						self.publish('gs1:newGlobalMessage', {
							type: 'success',
							text: self.get('i18n').t('gtins.nextAvailable.full')
						});
					}
				},
				error: function error(jqXHR, status, _error) {
					self.publish('gs1:newGlobalError', {
						text: status
					});
				}
			});
		},
		sendDeleteRequest: function sendDeleteRequest(gtin, deletionReason) {
			var self = this;

			var promise = new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.$.ajax({
					type: 'POST',
					url: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/ajax/delete_gtin',
					data: {
						gtin: gtin.get('gtinValue'),
						comment: deletionReason
					},
					headers: {
						'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
					},
					success: function success(response) {
						console.log(response);
						resolve(response);
						/*
      if (response.next_available_gtin) {
      	controller.set('nextAvailableGtin', response.next_available_gtin);
      }
      else {
      	self.publish('gs1:newGlobalMessage', {
      		type: 'success',
      		text: self.get('i18n').t('gtins.nextAvailable.full')
      	});
      }
      */
					},
					error: function error(jqXHR, status, _error2) {
						console.log('Cvrc ' + status);
						reject(jqXHR.responseJSON);
						/*
      self.publish('gs1:newGlobalError', {
      	text: status
      });
      */
					}
				});
			});

			return promise;

			/*
   Ember.$.ajax({
   	type: 'POST',
   	url: ENV.gs1.serverEndpoint + '/' + ENV.gs1.apiNamespace + '/ajax/delete',
   	data: {
   		gtin: '0896786',
   		comment: 'Lalalalala'
   	},
   	headers: {
   		'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
   	},
   	success: (response) => {
   		console.log(response);
   		if (response.next_available_gtin) {
   			controller.set('nextAvailableGtin', response.next_available_gtin);
   		}
   		else {
   			self.publish('gs1:newGlobalMessage', {
   				type: 'success',
   				text: self.get('i18n').t('gtins.nextAvailable.full')
   			});
   		}
   	},
   	error: (jqXHR, status, error) => {
   		console.log('Cvrc ' + status);
   		self.publish('gs1:newGlobalError', {
   			text: status
   		});
   	}
   });
   */
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			setTimeout(function () {
				var field = Ember.$('#gtins-search-by-term');
				if (field.val().length) field.focus();
			}, 100);

			if (Ember.isEmpty(controller.get('termBuffer')) && !Ember.isEmpty(controller.get('term'))) {
				controller.set('termBuffer', controller.get('term'));
			}

			var companyPrefix = this.modelFor('members.single.company-prefixes.single');

			controller.set('companyPrefix', companyPrefix);

			this.getNextAvailableGtin(controller, companyPrefix.get('id'));
		},


		duplicate: function duplicate(oldObject) {
			var newObject = oldObject.toJSON();
			for (var key in newObject) {
				if (newObject[key] != oldObject.get(key)) {
					newObject[key] = oldObject.get(key);
				}
			}
			newObject.id = null;
			delete newObject.id;
			return newObject;
		},

		actions: {
			clearCurrentGtin: function clearCurrentGtin() {
				this.getRidOfCurrentGtin();
			},
			editNewGtin: function editNewGtin() {
				this.getRidOfCurrentGtin();
				this.set('controller.currentGtin', this.get('store').createRecord('gtin'));
			},
			editGtin: function editGtin(gtin) {
				this.getRidOfCurrentGtin();
				this.set('controller.currentGtin', gtin);
			},
			viewGtin: function viewGtin(gtin) {
				this.getRidOfCurrentGtin();
				console.log(gtin);
				this.set('controller.currentGtin', gtin);
			},
			showDeleteGtin: function showDeleteGtin(gtin) {
				this.getRidOfCurrentGtin();
				this.set('controller.currentGtin', gtin);
			},
			duplicateGtin: function duplicateGtin(gtin) {
				this.getRidOfCurrentGtin();
				this.set('controller.cloneGtin', gtin);
				this.set('controller.currentGtin', this.get('store').createRecord('gtin', this.duplicate(gtin)));
			},
			saveGtin: function saveGtin() {
				var self = this;
				if (!self.get('controller.currentGtin')) {
					//console.error('Nothing to save');
					return Ember.RSVP.reject();
				} else {
					self.set('controller.currentGtin.member', self.modelFor('members.single'));
					self.set('controller.currentGtin.companyPrefix', self.modelFor('members.single.company-prefixes.single'));
					return self.get('controller.currentGtin').save().then(function (gtin) {
						self.refresh();
						return Ember.RSVP.resolve(gtin);
					}).catch(function (errors) {
						return Ember.RSVP.reject(errors);
					});
				}
			},
			deleteGtin: function deleteGtin(deletionReason) {
				var self = this;
				var currentGtin = self.get('controller.currentGtin');
				if (!currentGtin) {
					console.error('Nothing to delete');
					return Ember.RSVP.reject();
				} else {
					return self.sendDeleteRequest(currentGtin, deletionReason);

					return self.get('controller.currentGtin').save().then(function (gtin) {
						self.refresh();
						return Ember.RSVP.resolve(gtin);
					}).catch(function (errors) {
						return Ember.RSVP.reject(errors);
					});
				}
			}
		}
	});
});