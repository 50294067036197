define('gs1-frontend/mixins/pageable-route', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Mixin = Ember.Mixin;
	exports.default = Mixin.create({
		queryParams: {
			page: {
				refreshModel: true,
				replace: true
			}
		},

		pageableModel: function pageableModel(modelName, nameInTemplate, options, routeParams) {
			var self = this;
			var options = Ember.isEmpty(options) ? {} : options;

			if (!options.page && !Ember.isEmpty(routeParams.page)) {
				options.page = {
					number: routeParams.page - 1
				};
			}

			var pageableModel = {};

			pageableModel[nameInTemplate] = _emberData.default.PromiseArray.create({
				promise: self.get('store').query(modelName, options).then(function (results) {
					//self.set('controller.totalPages', results.get('meta.total_pages'));
					self.set('totalPages', results.get('meta.total_pages'));
					self.set('totalRecords', results.get('meta.total_records'));
					return results;
				})
			});

			return Ember.RSVP.hash(pageableModel);
		},
		setupController: function setupController(controller, model) {
			this._super(controller, model);

			controller.set('totalPages', this.get('totalPages'));
			controller.set('totalRecords', this.get('totalRecords'));
		}
	});
});