define('gs1-frontend/pods/gtin-list/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'gs1-frontend/mixins/pageable-route', 'gs1-frontend/mixins/eventable', 'gs1-frontend/config/environment'], function (exports, _authenticatedRouteMixin, _pageableRoute, _eventable, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_authenticatedRouteMixin.default, _pageableRoute.default, _eventable.default, {
		queryParams: {
			gepirStatus: {
				refreshModel: true,
				replace: true
			},
			status: {
				refreshModel: true,
				replace: true
			},
			term: {
				refreshModel: true,
				replace: true
			},
			classificationSegment: {
				refreshModel: true,
				replace: true
			},
			classificationFamily: {
				refreshModel: true,
				replace: true
			},
			classificationClass: {
				refreshModel: true,
				replace: true
			},
			classificationBrick: {
				refreshModel: true,
				replace: true
			}
		},

		i18n: Ember.inject.service(),

		getRidOfCurrentGtin: function getRidOfCurrentGtin() {
			var currentGtin = this.get('controller.currentGtin');
			if (currentGtin) {
				if (!currentGtin.get('id')) {
					currentGtin.destroyRecord();
				} else {
					currentGtin.rollbackAttributes();
				}
				this.set('controller.currentGtin', null);
			}
		},
		beforeModel: function beforeModel() {
			var privileges = this.get('currentUser.user.privileges');
			if (!(privileges && (privileges.toArray().includes('admin') || privileges.toArray().includes('superadmin')))) {
				this.publish('gs1:newGlobalError', {
					title: 'You attempted to visit a privileged page.'
				});
				this.transitionTo('index');
			}
		},
		model: function model(params) {
			var options = {};

			if (!Ember.isEmpty(params.gepirStatus)) {
				if (options.filter) {
					options.filter.gepir_status = params.gepirStatus;
				} else {
					options.filter = {
						gepir_status: params.gepirStatus
					};
				}
			}

			if (!Ember.isEmpty(params.status)) {
				if (options.filter) {
					options.filter.status = params.status;
				} else {
					options.filter = {
						status: params.status
					};
				}
			}

			if (!Ember.isEmpty(params.term)) {
				if (options.filter) {
					options.filter.term = params.term;
				} else {
					options.filter = {
						term: params.term
					};
				}
			}

			if (!Ember.isEmpty(params.classificationSegment)) {
				if (options.filter) {
					options.filter.classification_segment_id = params.classificationSegment;
				} else {
					options.filter = {
						classification_segment_id: params.classificationSegment
					};
				}
			}

			if (!Ember.isEmpty(params.classificationFamily)) {
				if (options.filter) {
					options.filter.classification_family_id = params.classificationFamily;
				} else {
					options.filter = {
						classification_family_id: params.classificationFamily
					};
				}
			}

			if (!Ember.isEmpty(params.classificationClass)) {
				if (options.filter) {
					options.filter.classification_class_id = params.classificationClass;
				} else {
					options.filter = {
						classification_class_id: params.classificationClass
					};
				}
			}

			if (!Ember.isEmpty(params.classificationBrick)) {
				if (options.filter) {
					options.filter.classification_brick_id = params.classificationBrick;
				} else {
					options.filter = {
						classification_brick_id: params.classificationBrick
					};
				}
			}

			return this.pageableModel('gtin', 'gtins', options, params);
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			setTimeout(function () {
				var field = Ember.$('#gtin-list-search-field');
				if (field.val().length) field.focus();
			}, 100);

			if (Ember.isEmpty(controller.get('termBuffer')) && !Ember.isEmpty(controller.get('term'))) {
				controller.set('termBuffer', controller.get('term'));
			}
		},


		actions: {
			changeGepirStatus: function changeGepirStatus(gtin) {
				var self = this;

				Ember.$.ajax({
					type: 'GET',
					url: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/ajax/update_gepir/' + gtin.id,
					headers: {
						'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
					},
					success: function success(response) {
						self.publish('gs1:newGlobalMessage', {
							type: 'success',
							text: self.get('i18n').t('gtins.attributes.gepirStatus.updated')
						});
						self.refresh();
					},
					error: function error(jqXHR, status, _error) {
						self.publish('gs1:newGlobalError', {
							text: status
						});
					}
				});
			},
			performGepirExport: function performGepirExport() {
				var self = this;
				var linkToExport = _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/gepir_exports/0?authorization=' + self.get('session.data.authenticated.access_token');

				Ember.$.ajax({
					type: 'GET',
					url: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/ajax/gepir',
					//url: linkToExport,
					headers: {
						'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
					},
					success: function success(response) {
						if (response.available) {
							var url = response.url;
							window.location = _environment.default.gs1.serverEndpoint + url + '?authorization=' + self.get('session.data.authenticated.access_token');
							// Refresh the route to preview updated colums
							self.refresh();
							self.publish('gs1:newGlobalMessage', {
								type: 'success',
								text: self.get('i18n').t('gtins.gepirExport.successfullyExport')
							});
						} else {
							self.publish('gs1:newGlobalMessage', {
								type: 'success',
								text: self.get('i18n').t('gtins.gepirExport.nothingToExport')
							});
						}
					},
					error: function error(jqXHR, status, _error2) {
						self.publish('gs1:newGlobalError', {
							text: status
						});
					}
				});
			},
			clearCurrentGtin: function clearCurrentGtin() {},
			saveGtin: function saveGtin() {
				var self = this;
				if (!self.get('controller.currentGtin')) {
					//console.error('Nothing to save');
					return Ember.RSVP.reject();
				} else {
					self.set('controller.currentGtin.member', self.modelFor('members.single'));
					self.set('controller.currentGtin.companyPrefix', self.modelFor('members.single.company-prefixes.single'));
					return self.get('controller.currentGtin').save().then(function (gtin) {
						self.refresh();
						return Ember.RSVP.resolve(gtin);
					}).catch(function (errors) {
						return Ember.RSVP.reject(errors);
					});
				}
			},
			editGtin: function editGtin(gtin) {
				// this.getRidOfCurrentGtin();
				this.set('controller.currentGtin', gtin);
			},
			editNewGtin: function editNewGtin() {
				this.getRidOfCurrentGtin();
				this.set('controller.currentGtin', this.get('store').createRecord('gtin'));
			},
			viewGtin: function viewGtin(gtin) {
				this.getRidOfCurrentGtin();
				this.set('controller.currentGtin', gtin);
			}
		}

		// Addons

	});
});