define('gs1-frontend/pods/components/ean8/-duplicate-ean8/component', ['exports', 'gs1-frontend/pods/components/modals/-modal/component', 'gs1-frontend/mixins/errorable'], function (exports, _component, _errorable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend(_errorable.default, {

		id: 'modalDuplicateEan8',

		onCloseBound: false,

		ean8Array: [{ value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }],

		ean8ValueObserver: Ember.observer('ean8Array.@each.value', function () {
			var ean8Value = '';
			var ean8Arr = this.get('ean8Array').mapBy('value');

			ean8Arr.forEach(function (digit, index) {
				ean8Value += digit.length ? digit : '0';
			});
			this.set('currentEan8Value', ean8Value);
		}),

		fillEan8Array: function fillEan8Array() {
			var _this = this;

			var mandatoryLength = 8;
			var next = this.get('nextAvailableEan8');
			var lockedIndex = mandatoryLength;

			if (this.get('ean8').get('ean8Value')) {
				var value = this.get('ean8').get('ean8Value');
				value = value.substr(0, value.length - 1);
				this.get('ean8Array').forEach(function (digit, index) {
					if (index < value.length) {
						Ember.set(digit, 'value', value[index]);
						Ember.set(digit, 'editable', _this.get('ean8Array')[index].editable);
					}
				});
			} else {
				var value = '00000000';
				this.get('ean8Array').forEach(function (digit, index) {
					Ember.set(digit, 'value', value[index]);
					Ember.set(digit, 'editable', _this.get('ean8Array')[index].editable);
				});
			}
		},


		ean8ControlDigit: Ember.computed('ean8Array.@each.value', function () {
			return this.calculateEan8ControlDigit(this.get('ean8Array').mapBy('value'));
		}),

		calculateEan8ControlDigit: function calculateEan8ControlDigit(ean8Arr) {
			var sum = 0,
			    controlDigit = '',
			    ean8Value = '';
			for (var i = 0; i < ean8Arr.length; i++) {
				sum += (i % 2 ? 1 : 3) * parseInt(ean8Arr.objectAt(ean8Arr.length - 1 - i));
			}
			controlDigit = (10 - sum % 10) % 10;
			ean8Arr.forEach(function (digit) {
				ean8Value += digit;
			});
			ean8Value += controlDigit;
			this.set('currentEan8Value', ean8Value);
			return '' + controlDigit ? controlDigit : 'x';
		},


		/**
   * Available Ean-8  - get from resource
   */
		availableEan8: Ember.computed(function () {
			var eans = [];
			return this.get('eans').filter(function (element) {
				if (eans.indexOf(element.get('ean8Value')) === -1) {
					eans.push(element.get('ean8Value'));
					return element;
				}
				return;
			});
		}),

		selectedEan8: '',

		// selectedEan8Observer: Ember.observer('selectedEan8', function() {
		// 	if(this.get('selectedEan8') !== ""){
		// 		this.set('currentEan8Value', this.get('selectedEan8'));
		// 	}else{
		// 		this.set('currentEan8Value', null)
		// 	}
		// }),

		formFields: Ember.computed(function () {
			return this.get('store').query('form-field', {
				'filter[field]': 'gtin,sale_country_id,brick_id,segment_id,class_id,family_id,brand_name,full_name,language,trade_item_unit_id,product_status_id,data_provider_gln'
			});
		}),

		fieldHelpContent: Ember.computed('formFields.[]', function () {
			var arr = {};
			this.get('formFields').forEach(function (item, index) {
				arr[item.get('field')] = item.get('help_text');
			});
			return arr;
		}),

		allCountries: Ember.computed(function () {
			return this.get('store').findAll('country');
		}),

		selectedCountry: '',

		selectedCountryObserver: Ember.observer('selectedCountry', function () {
			var ccountry = this.get('allCountries').findBy('id', this.get('selectedCountry'));
			if (ccountry) {
				this.get('ean8').set('targetCountry', ccountry);
			} else {
				this.get('ean8').set('targetCountry', null);
			}
		}),

		allRegions: Ember.computed(function () {
			return this.get('store').findAll('region');
		}),

		selectedRegion: '',

		selectedRegionObserver: Ember.observer('selectedRegion', function () {
			var region = this.get('allRegions').findBy('id', this.get('selectedRegion'));
			if (region) {
				this.get('ean8').set('targetRegion', region);
			} else {
				this.get('ean8').set('targetRegion', null);
			}
		}),

		allLanguages: Ember.computed(function () {
			return this.get('store').findAll('language');
		}),

		selectedLanguage: '',

		selectedLanguageObserver: Ember.observer('selectedLanguage', function () {
			var language = this.get('allLanguages').findBy('id', this.get('selectedLanguage'));
			if (language) {
				this.get('ean8').set('language', language);
			} else {
				this.get('ean8').set('language', null);
			}
		}),

		allTradeItemUnits: Ember.computed(function () {
			return this.get('store').findAll('trade-item-unit');
		}),

		selectedTradeItemUnit: '',

		selectedTradeItemUnitObserver: Ember.observer('selectedTradeItemUnit', function () {
			var unit = this.get('allTradeItemUnits').findBy('id', this.get('selectedTradeItemUnit'));
			if (unit) {
				this.get('ean8').set('tradeItemUnit', unit);
			} else {
				this.get('ean8').set('tradeItemUnit', null);
			}
		}),

		allStatuses: Ember.computed(function () {
			return ['1', '2', '3', '4'];
		}),

		selectedStatus: '',

		selectedStatusObserver: Ember.observer('selectedStatus', function () {
			var status = this.get('selectedStatus');
			if (status) {
				this.get('ean8').set('status', status);
			} else {
				this.get('ean8').set('status', null);
			}
		}),

		allMembers: Ember.computed(function () {
			return this.get('store').findAll('member');
		}),

		selectedMember: '',

		selectedMemberObserver: Ember.observer('selectedMember', function () {
			var member = this.get('allMembers').findBy('id', this.get('selectedMember'));
			if (member) {
				this.get('ean8').set('dataProviderGln', member);
				this.get('ean8').set('informationProviderGln', member.get('masterGlnNumber'));
			} else {
				this.get('ean8').set('dataProviderGln', null);
				this.get('ean8').set('informationProviderGln', null);
			}
		}),

		firstChange: {
			segment: true,
			family: true,
			class: true,
			brick: true
		},

		allClassificationSegments: Ember.computed(function () {
			return this.get('store').findAll('classification-segment');
		}),

		classificationSegment: '',

		segmentObserver: Ember.observer('classificationSegment', function () {
			if (1 || !this.get('firstChange.segment')) {
				this.set('classificationFamily', '');
			} else {
				this.set('firstChange.segment', false);
			}
		}),

		allClassificationFamilies: Ember.computed('allClassificationSegments.[]', 'classificationSegment', function () {
			var segments = this.get('allClassificationSegments');
			if (segments.length) {
				var segment = segments.findBy('id', this.get('classificationSegment'));
				if (segment) {
					return segment.get('classificationFamilies');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		classificationFamily: '',

		familyObserver: Ember.observer('classificationFamily', function () {
			if (1 || !this.get('firstChange.family')) {
				this.set('classificationClass', '');
			} else {
				this.set('firstChange.family', false);
			}
		}),

		allClassificationClasses: Ember.computed('allClassificationFamilies.[]', 'classificationFamily', function () {
			var families = this.get('allClassificationFamilies');
			if (families.length) {
				var family = families.findBy('id', this.get('classificationFamily'));
				if (family) {
					return family.get('classificationClasses');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		classificationClass: '',

		classObserver: Ember.observer('classificationClass', function () {
			if (1 || !this.get('firstChange.class')) {
				this.set('classificationBrick', '');
			} else {
				this.set('firstChange.class', false);
			}
		}),

		allClassificationBricks: Ember.computed('allClassificationClasses.[]', 'classificationClass', function () {
			var classes = this.get('allClassificationClasses');
			if (classes.length) {
				var theClass = classes.findBy('id', this.get('classificationClass'));
				if (theClass) {
					return theClass.get('classificationBricks');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		classificationBrick: '',

		brickObserver: Ember.observer('classificationBrick', function () {
			if (1 || !this.get('firstChange.brick')) {
				var brick = this.get('allClassificationBricks').findBy('id', this.get('classificationBrick'));
				if (brick) {
					this.get('ean8').set('classificationBrick', brick);
				} else {
					this.get('ean8').set('classificationBrick', null);
				}
			} else {
				this.set('firstChange.brick', false);
			}
		}),

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var element = Ember.$(this.get('element'));

			element.on('click', function (e) {
				var target = Ember.$(e.target);
				var targetHelp = target.closest('.help');
				if (!targetHelp.length) {
					element.find('.help-content').hide();
				} else {
					if (target.closest('.question-mark').length) {
						targetHelp.find('.help-content').show();
					} else if (target.closest('.help-close').length) {
						targetHelp.find('.help-content').hide();
					}

					element.find('.help').not(targetHelp).find('.help-content').hide();
				}
			});
		},
		didReceiveAttrs: function didReceiveAttrs() {
			var currentMemberId = this.get('companyPrefix.member.id');
			if (currentMemberId) {
				if (this.get('ean8') && !this.get('ean8.id')) {
					this.set('selectedMember', currentMemberId);
					this.notifyPropertyChange('selectedMember');
				}
			}
		},
		didRender: function didRender() {
			this._super.apply(this, arguments);

			var self = this;

			if (!self.get('onCloseBound')) {
				var onClose = self.get('onClose');
				if (typeof onClose === 'function') {
					Ember.$(self.get('element')).on('hide.bs.modal', onClose);
				}

				Ember.$(self.get('element')).on('hide.bs.modal', function () {
					self.resetErrors();
				});

				self.set('selectedRegion', '');
				self.set('selectedCountry', '');
				self.set('imageUploading', false);
				self.set('imageUploaded', false);
				self.set('onCloseBound', true);
			}

			if (!self.get('onShowBound')) {
				Ember.$(self.get('element')).on('show.bs.modal', function () {
					var ean8 = self.get('ean8');
					if (ean8) {
						var brick, theClass, family, segment;

						ean8.get('classificationBrick').then(function (obtainedBrick) {
							if (obtainedBrick && obtainedBrick.get('id')) {
								brick = obtainedBrick;
								return obtainedBrick.get('classificationClass');
							}
						}).then(function (obtainedClass) {
							if (obtainedClass && obtainedClass.get('id')) {
								theClass = obtainedClass;
								return obtainedClass.get('classificationFamily');
							}
						}).then(function (obtainedFamily) {
							if (obtainedFamily && obtainedFamily.get('id')) {
								family = obtainedFamily;
								return obtainedFamily.get('classificationSegment');
							}
						}).then(function (obtainedSegment) {
							if (obtainedSegment && obtainedSegment.get('id')) {
								segment = obtainedSegment;
							}
						}).finally(function () {
							self.set('classificationSegment', segment ? segment.get('id') : '');
							self.set('classificationFamily', segment && family ? family.get('id') : '');
							self.set('classificationClass', segment && family && theClass ? theClass.get('id') : '');
							self.set('classificationBrick', segment && family && theClass && brick ? brick.get('id') : '');
						});

						var language;
						ean8.get('language').then(function (obtainedLanguage) {
							if (obtainedLanguage && obtainedLanguage.get('id')) {
								language = obtainedLanguage;
							} else {
								language = self.get('allLanguages').filterBy('isDefault', true).objectAt(0);
							}
						}).finally(function () {
							self.set('selectedLanguage', language ? language.get('id') : '');
							self.notifyPropertyChange('selectedLanguage');
						});

						var sCountry;
						ean8.get('targetCountry').then(function (obtainedCountry) {
							if (obtainedCountry && obtainedCountry.get('id')) {
								sCountry = obtainedCountry;
							}
						}).finally(function () {
							self.set('selectedCountry', sCountry.get('id'));
							self.notifyPropertyChange('selectedCountry');
						});

						var status = ean8.get('status');
						self.set('selectedStatus', '1');
						self.notifyPropertyChange('selectedStatus');
						ean8.set('mediumResImageToken', 'duplicate');

						//self.ean8ArrayObserver();
						self.fillEan8Array();
						self.notifyPropertyChange('ean8Array');
					}
				});

				self.set('onShowBound', true);
			}

			if (!self.get('focusesBound')) {
				var inputs = Ember.$(self.get('element')).find('.ean8-digits-table input');
				inputs.on('focus', function () {
					Ember.$(this).select();
				});
				inputs.on('change', function () {
					console.log(Ember.$(this).val());
				});

				self.set('focusesBound', true);
			}
			Ember.$(self.get('element')).find('#duplicate-ean8-image-input').fileTransport({
				start: function start() {
					self.set('imageUploading', true);
					self.set('imageUploaded', false);
					self.set('imageError', null);
				},
				success: function success(event, file, token, meta, data) {
					self.set('imageUploading', false);
					self.set('imageUploaded', true);
					self.set('imageError', null);
					self.set('ean8.mediumResImage', meta.thumbnail);
					self.set('ean8.mediumResImageToken', token);
				},
				error: function error(event, id, message) {
					self.set('imageError', message);
					self.set('imageUploading', false);
					self.set('imageUploaded', false);
				}
			});
		},


		actions: {
			digitClicked: function digitClicked() {
				this.$().find('.ean8-digits-table input:focus').select();
			},
			keyPressed: function keyPressed() {
				var allowed = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
				var input = this.$().find('.ean8-digits-table input:focus');
				if (input.val().length > 1) {
					input.val(input.val().substr(0, 1));
				}
				if (!allowed.includes(input.val())) {
					input.val('0');
				}
			},
			keyUp: function keyUp() {
				var allowed = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
				var input = this.$().find('.ean8-digits-table input:focus');
				if (input.val().length > 1) {
					input.val(input.val().substr(0, 1));
				}
				if (!allowed.includes(input.val())) {
					input.val('0');
				}
				input.closest('td').next('td').find('input').focus();
			},
			removeImage: function removeImage() {
				this.set('ean8.mediumResImageToken', null);
				this.set('ean8.mediumResImage', null);
			},
			saveEan8: function saveEan8() {
				var self = this;
				// self.set('ean8.ean8Value', self.get('currentEan8Value'));

				var onSave = self.get('onSave');
				if (typeof onSave === 'function') {
					onSave().then(function () {
						//console.log('Successful');
						self.closeModal();
					}).catch(function (response) {
						self.handleErrors(response.errors);
					}).finally(function () {
						//self.closeModal();
					});
				}
			}
		}

	});
});