define('gs1-frontend/pods/application/adapter', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'gs1-frontend/config/environment', 'gs1-frontend/mixins/eventable'], function (exports, _emberData, _dataAdapterMixin, _environment, _eventable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPIAdapter.extend(_eventable.default, _dataAdapterMixin.default, {
		headers: {
			//"X-HTTP-Method-Override": "PATCH"
		},
		namespace: _environment.default.gs1.apiNamespace,
		host: _environment.default.gs1.serverEndpoint,
		shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
			return true;
		},

		authorizer: 'authorizer:application',
		handleResponse: function handleResponse(status, headers, payload, requestData) {
			if (_environment.default.gs1.errorStatusesInUse.includes(status)) {
				this.publish("gs1:adapterError", status, payload, requestData);
			}

			return this._super(status, headers, payload, requestData);
		}
	});
});