define('gs1-frontend/pods/login/route', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {

    actions: {
      didTransition: function didTransition() {
        this._super.apply(this, arguments);

        Ember.$('body').addClass('login-page');
      },
      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        Ember.$('body').removeClass('login-page');
      }
    }
  });
});