define('gs1-frontend/pods/members/single/company-prefixes/single/gtins/single/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		model: function model(params) {
			return this.get('store').find('gtin', params.gtin_id);
		}
	});
});