define('gs1-frontend/helpers/in-array', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.inArray = inArray;
	var helper = Ember.Helper.helper;
	function inArray(params /*, hash*/) {
		var needle = params[0],
		    haystack = params[1];

		return haystack.toArray().indexOf(needle) > -1;
	}

	exports.default = helper(inArray);
});