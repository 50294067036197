define('gs1-frontend/pods/members/single/company-prefixes/single/gtins/index-loading/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_authenticatedRouteMixin.default, {
		setupController: function setupController(controller, model) {
			var mainController = this.controllerFor('members.single.company-prefixes.single.gtins.index');

			controller.set('termBuffer', mainController.get('termBuffer'));

			controller.set('status', mainController.get('status'));
		}
	});
});