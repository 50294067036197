define('gs1-frontend/pods/gtin-list/controller', ['exports', 'gs1-frontend/mixins/pageable-route-controller'], function (exports, _pageableRouteController) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend(_pageableRouteController.default, {
		queryParams: [{
			gepirStatus: {
				type: 'string'
			}
		}, {
			status: {
				type: 'string'
			}
		}, {
			term: {
				type: 'string'
			}
		}, {
			classificationSegment: {
				type: 'string'
			}
		}, {
			classificationFamily: {
				type: 'string'
			}
		}, {
			classificationClass: {
				type: 'string'
			}
		}, {
			classificationBrick: {
				type: 'string'
			}
		}],

		gepirStatus: '',

		status: '',

		term: '',

		classificationSegment: '',

		classificationFamily: '',

		classificationClass: '',

		classificationBrick: '',

		firstChange: {
			segment: true,
			family: true,
			class: true
		},

		segmentObserver: Ember.observer('classificationSegment', function () {
			if (!this.get('firstChange.segment')) {
				this.set('classificationFamily', '');
			} else {
				this.set('firstChange.segment', false);
			}
			if (this.get('page') > 1) {
				this.set('page', 1);
			}
		}),

		statusObserver: Ember.observer('status', function () {
			if (this.get('page') > 1) {
				this.set('page', 1);
			}
		}),
		gepirStatusObserver: Ember.observer('gepirStatus', function () {
			if (this.get('page') > 1) {
				this.set('page', 1);
			}
		}),

		selectedSegment: Ember.computed('allClassificationSegments.[]', 'classificationSegment', function () {
			var allClassificationSegments = this.get('allClassificationSegments');
			if (allClassificationSegments && allClassificationSegments.length) {
				return allClassificationSegments.findBy('id', this.get('classificationSegment'));
			} else {
				return null;
			}
		}),

		familyObserver: Ember.observer('classificationFamily', function () {
			if (!this.get('firstChange.family')) {
				this.set('classificationClass', '');
			} else {
				this.set('firstChange.family', false);
			}
			if (this.get('page') > 1) {
				this.set('page', 1);
			}
		}),

		selectedFamily: Ember.computed('allClassificationFamilies.[]', 'classificationFamily', function () {
			var allClassificationFamilies = this.get('allClassificationFamilies');
			if (allClassificationFamilies && allClassificationFamilies.length) {
				return allClassificationFamilies.findBy('id', this.get('classificationFamily'));
			} else {
				return null;
			}
		}),

		classObserver: Ember.observer('classificationClass', function () {
			if (!this.get('firstChange.class')) {
				this.set('classificationBrick', '');
			} else {
				this.set('firstChange.class', false);
			}
			if (this.get('page') > 1) {
				this.set('page', 1);
			}
		}),

		selectedClass: Ember.computed('allClassificationClasses.[]', 'classificationClass', function () {
			var allClassificationClasses = this.get('allClassificationClasses');
			if (allClassificationClasses && allClassificationClasses.length) {
				return allClassificationClasses.findBy('id', this.get('classificationClass'));
			} else {
				return null;
			}
		}),

		allClassificationSegments: Ember.computed(function () {
			return this.get('store').findAll('classification-segment');
		}),

		allClassificationFamilies: Ember.computed('allClassificationSegments.[]', 'classificationSegment', function () {
			var segments = this.get('allClassificationSegments');
			if (segments.length) {
				var segment = segments.findBy('id', this.get('classificationSegment'));
				if (segment) {
					return segment.get('classificationFamilies');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		allClassificationClasses: Ember.computed('allClassificationFamilies.[]', 'classificationFamily', function () {
			var families = this.get('allClassificationFamilies');
			if (families.length) {
				var family = families.findBy('id', this.get('classificationFamily'));
				if (family) {
					return family.get('classificationClasses');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		allClassificationBricks: Ember.computed('allClassificationClasses.[]', 'classificationClass', function () {
			var classes = this.get('allClassificationClasses');
			if (classes.length) {
				var theClass = classes.findBy('id', this.get('classificationClass'));
				if (theClass) {
					return theClass.get('classificationBricks');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		termBufferObserver: Ember.observer("termBuffer", function () {
			var _this = this;

			clearTimeout(this.get("bufferTimeout"));
			this.set("bufferTimeout", setTimeout(function () {
				_this.refresh();
			}, 2000));
		}),

		// Refresh the model based on the current data
		refresh: function refresh() {

			// Set the search parameters
			this.set("term", this.get("termBuffer"));
			this.set("page", 1);

			// Reset timers
			clearTimeout(this.get("bufferTimeout"));
		},

		actions: {
			refresh: function refresh() {
				this.refresh();
			}
		}

	});
});