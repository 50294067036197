define('gs1-frontend/pods/member-registration/step-5/route', ['exports', 'gs1-frontend/mixins/errorable'], function (exports, _errorable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_errorable.default, {
		beforeModel: function beforeModel() {
			var member = Ember.get(this.modelFor('member-registration'), 'member');
			if (Ember.isEmpty(member) || Ember.isEmpty(Ember.get(member, 'memberType')) || Ember.get(member, 'memberType') == 1 && Ember.isEmpty(Ember.get(member, 'crn')) || Ember.get(member, 'memberType') == 2 && Ember.isEmpty(Ember.get(member, 'jmbg'))) {
				this.transitionTo('member-registration');
			}
		},


		actions: {
			moveForward: function moveForward() {
				this.transitionTo('member-registration.step-6');
			},
			saveMember: function saveMember() {
				var self = this;
				self.get('controller.model.member').save().then(function (response) {
					console.log("Success in saving!");
					console.log(response);
					self.resetErrors();
					self.transitionTo('member-registration.confirmation');
				}).catch(function (response) {
					console.log("Failed in saving");
					console.log(response.errors);
					self.handleErrors(response.errors);
				}).finally(function () {
					console.log(self.get('errors'));
					self.get('controller').set('model.errors', self.get('errors'));
					if (self.get('errors').length) {
						self.transitionTo('member-registration.step-1');
					}
				});
			},
			goBack: function goBack() {
				this.transitionTo('member-registration.step-4');
			}
		}
	});
});