define('gs1-frontend/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		fullName: _emberData.default.attr('string'),
		companyName: _emberData.default.attr('string'),
		email: _emberData.default.attr('string'),
		privileges: _emberData.default.attr(),

		member: _emberData.default.belongsTo('member')
	});
});