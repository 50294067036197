define('gs1-frontend/pods/components/main/-footer/component', ['exports', 'gs1-frontend/pods/components/-base/base-component/component'], function (exports, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend({

		classNames: ['main-footer'],
		tagName: 'footer',

		didRender: function didRender() {
			//console.log('Firing resize');
			Ember.$(window).resize();
		}
	});
});