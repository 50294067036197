define('gs1-frontend/pods/components/modals/-modal/component', ['exports', 'gs1-frontend/pods/components/-base/base-component/component'], function (exports, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend({

		classNames: ['modal', 'fade'],

		attributeBindings: ['id'],

		id: null,

		openModal: function openModal() {
			Ember.$(this.get('element')).modal('show');
		},
		closeModal: function closeModal() {
			Ember.$(this.get('element')).modal('hide');
		}
	});
});