define('gs1-frontend/pods/members/single/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_authenticatedRouteMixin.default, {
		model: function model(params) {
			return this.get('store').findRecord('member', params.member_id);
		},
		afterModel: function afterModel(model /*, transition */) {
			this.controllerFor('application').set('activeMember', model);
		},
		deactivate: function deactivate() {
			this.controllerFor('application').set('activeMember', null);
		},


		actions: {}
	});
});