define('gs1-frontend/pods/member-registration/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({

		bezzes: ['look', 'here', 'now']

	});
});