define('gs1-frontend/pods/member-registration/step-2/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({

		deliveryObserver: Ember.observer('model.differentDeliveryAddress', function () {
			if (Ember.isEmpty(this.get('model.differentDeliveryAddress'))) {
				this.set('model.member.nameDelivery', null);
				this.set('model.member.addressDelivery', null);
				this.set('model.member.zipDelivery', null);
				this.set('model.member.cityDelivery', null);
			}
		})

	});
});