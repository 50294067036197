define('gs1-frontend/models/region', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		name: _emberData.default.attr('string'),
		isDefault: _emberData.default.attr('boolean'),

		gtins: _emberData.default.hasMany('gtin'),
		country: _emberData.default.belongsTo('country')
	});
});