define('gs1-frontend/models/classification-segment', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		name: _emberData.default.attr('string'),
		code: _emberData.default.attr('string'),
		description: _emberData.default.attr('string'),

		classificationFamilies: _emberData.default.hasMany('classification-family')
	});
});