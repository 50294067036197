define('gs1-frontend/helpers/array-member-by-attribute', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.arrayMemberByAttribute = arrayMemberByAttribute;
		var helper = Ember.Helper.helper;
		function arrayMemberByAttribute(params /*, hash*/) {
				var arr = params[0],
				    attr = params[1],
				    val = params[2];

				return arr.findBy(attr, val);
		}

		exports.default = helper(arrayMemberByAttribute);
});