define('gs1-frontend/pods/components/members/-single-member-display/component', ['exports', 'gs1-frontend/pods/components/-base/base-component/component'], function (exports, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend({

		tagName: 'div',

		classNames: ['member-display-wrapper'],

		backgroundImageStyle: Ember.computed('member.logoUrl', function () {
			return Ember.String.htmlSafe("background: url('" + this.get('member.logoUrl') + "') no-repeat center");
		}),

		actions: {}

	});
});