define('gs1-frontend/pods/members/index-loading/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		setupController: function setupController(controller /*, model*/) {
			var mainController = this.controllerFor('members.index');

			// Copy the search parameters
			controller.set('termBuffer', mainController.get('termBuffer'));
			controller.set('activityCode', mainController.get('activityCode'));
			controller.set('allActivityCodes', mainController.get('allActivityCodes'));
			controller.set('status', mainController.get('status'));

			// Show loading animation
			controller.set('loading', true);
		}
	});
});