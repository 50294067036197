define("gs1-frontend/locales/sr-sp/translations", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		"something": "",

		"auth": {
			"login": {
				"title": "Prijava",
				"email": {
					"placeholder": "Vaš email"
				},
				"password": {
					"placeholder": "Vaša lozinka",
					"forgot": {
						"text": "Zaboravili ste lozinku?"
					}
				},
				"submit": {
					"text": "Prijava"
				},
				"registration": {
					"text": "Registracija nove članice"
				}
			}
		},

		"registration": {
			"basicForm": {
				"title": "Registracija članice",
				"submit": {
					"text": "Pošalji zahtev"
				},
				"company": {
					"name": "Ime preduzeća",
					"registrationNumber": "Matični broj",
					"taxNumber": "PIB",
					"personName": "Ime podnosioca zahteva",
					"contactPhone": "Telefon",
					"contactEmail": "Email"
				},
				"individual": {
					"name": "Ime poljoprivrednog gazdinstva",
					"personalNumber": "JMBG vlasnika",
					"agroNumber": "Broj poljoprivrednog gazdinstva",
					"personName": "Ime podnosioca zahteva",
					"contactPhone": "Telefon",
					"contactEmail": "Email"
				}
			},
			"fullForm": {
				"company": {
					"step1": {
						"title": "Podaci o sedištu"
					},
					"step2": {
						"title": "Kontakt informacije "
					},
					"step3": {
						"title": "Podaci o poslovnim bankama"
					},
					"step4": {
						"title": "Opseg/prenos KP brojeva"
					},
					"step5": {
						"title": "Proizvodi iz oblasti zdravstva"
					},
					"confirmation": {
						"title": "Registracija uspešna!"
					}
				},
				"individual": {
					"step1": {
						"title": "Podaci o sedištu"
					},
					"step2": {
						"title": "Kontakti vlasnika "
					},
					"step3": {
						"title": "Podaci o poslovnim bankama"
					},
					"step4": {
						"title": "Opseg/prenos KP brojeva"
					},
					"step5": {
						"title": "Proizvodi iz oblasti zdravstva"
					},
					"confirmation": {
						"title": "Registracija uspešna!"
					}
				},
				"general": {
					"return": "Naslovna strana"
				}
			},
			"errors": {
				"form": {
					"validation": {
						"jmbg": {
							"invalid": "Neispravan JMBG!",
							"already_exists": "Uneseni JMBG je već registrovan!"
						},
						"bpg": {
							"invalid": "Neispravan broj gazdinstva!"
						},
						"mb": {
							"invalid": "Neispravan matični broj!",
							"already_exists": "Uneseni matični broj je već registrovan!"
						},
						"pib": {
							"invalid": "Neispravan PIB!"
						},
						"contact_email": {
							"invalid": "Neispravan email!"
						},
						"contact_phone": {
							"invalid": "Neispravan broj telefona!"
						},
						"company_name": {
							"invalid": "Neispravno ime preduzeća!"
						},
						"person_name": {
							"invalid": "Neispravno ime!"
						},
						"name": {
							"invalid": "Neispravno ime preduzeća!"
						},
						"full_business_name": {
							"invalid": "Neispravno puno ime!"
						},
						"owner_name": {
							"invalid": "Neispravno ime vlasnika!"
						},
						"email": {
							"invalid": "Neispravan email!"
						},
						"url": {
							"invalid": "Neispravna adresa!"
						},
						"telephone": {
							"invalid": "Neispravan broj telefona!"
						},
						"cellphone": {
							"invalid": "Neispravan broj mobilnog telefona!"
						},
						"address": {
							"invalid": "Neispravna adresa!"
						},
						"crn": {
							"invalid": "Neispravan matični broj!",
							"already_exists": "Uneseni matični broj je već registrovan!"
						},
						"tin": {
							"invalid": "Neispravan PIB!"
						},
						"sd": {
							"invalid": "Neispravna šifra delatnosti!"
						},
						"city": {
							"invalid": "Neispravan grad!"
						},
						"zip": {
							"invalid": "Neispravan poštanski broj!"
						},
						"country": {
							"invalid": "Neispravna država!"
						},
						"number_range": {
							"invalid": "Neispravan opseg KP brojeva!"
						},
						"name_delivery": {
							"invalid": "Neispravno ime!"
						},
						"address_delivery": {
							"invalid": "Neispravna adresa!"
						},
						"zip_delivery": {
							"invalid": "Neispravan poštanski broj!"
						},
						"city_delivery": {
							"invalid": "Neispravan grad!"
						},
						"tmp_cp_transfer_code": {
							"invalid": "Neispravan transfer kod!"
						}
					},
					"response": {
						"error": "Forma sadrži neispravne podatke!"
					}
				}
			}
		},

		"members": {
			"title": {
				"singular": "Članica",
				"plural": "Članice"
			},
			"status": {
				"registered": "registrovani kandidat",
				"inJoiningProcess": "u procesu pristupanja",
				"active": "aktivna",
				"left": "istupila",
				"excluded": "isključena",
				"inActivationProcess": "u procesu aktivacije",
				"unknown": "nepoznat status"
			},
			"list": {
				"empty": "Nema članica za prikaz"
			},
			"attributes": {
				"name": {
					"prompt": "Skraćeno ime"
				},
				"fullBusinessName": {
					"prompt": "Puno poslovno ime"
				},
				"ownerName": {
					"prompt": "Ime vlasnika"
				},
				"email": {
					"prompt": "Email"
				},
				"url": {
					"prompt": "Web adresa"
				},
				"telephone": {
					"prompt": "Telefon"
				},
				"cellphone": {
					"prompt": "Mobilni telefon"
				},
				"fax": {
					"prompt": "Fax"
				},
				"status": {
					"prompt": "Status"
				},
				"masterGlnNumber": {
					"prompt": "GLN Broj"
				},
				"bankAccount": {
					"prompt": "Bankovni racun"
				},
				"contactPersonGs1": {
					"prompt": "Kontakt osoba iz GS1"
				},
				"address": {
					"prompt": "Adresa"
				},
				"crn": {
					"prompt": "Matični broj"
				},
				"tin": {
					"prompt": "Poreski identifikacioni broj"
				},
				"sd": {
					"prompt": "Šifra delatnosti"
				},
				"accountingCode": {
					"prompt": "Računovodstveni kod"
				},
				"city": {
					"prompt": "Grad"
				},
				"municipality": {
					"prompt": "Opština"
				},
				"place": {
					"prompt": "Mesto"
				},
				"zip": {
					"prompt": "Poštanski broj"
				},
				"nameDelivery": {
					"prompt": "Ime za prijem pošte"
				},
				"addressDelivery": {
					"prompt": "Adresa za prijem pošte"
				},
				"zipDelivery": {
					"prompt": "Poštanski broj za prijem pošte"
				},
				"cityDelivery": {
					"prompt": "Grad za prijem pošte"
				},
				"country": {
					"prompt": "Država"
				},
				"logoUrl": {
					"prompt": "Link za logo"
				}
			}
		},

		"bankAccounts": {
			"attributes": {
				"bankName": {
					"prompt": "Ime banke"
				},
				"number": {
					"prompt": "Broj računa"
				}
			}
		},

		"prefixes": {
			"title": {
				"singular": "Kompanijski prefiks",
				"plural": "Kompanijski prefiksi"
			},
			"singleDisplay": {
				"range": "Opseg",
				"differentItems": "{{count}} različitih artikala",
				"usedNumbers": "Iskorišćenih brojeva"
			},
			"status": {
				"active": "aktivni KP"
			}
		},

		"gtins": {
			"title": {
				"abbr": "GTIN",
				"singular": "GTIN broj",
				"plural": "GTIN brojevi"
			},
			"gepirExport": {
				"nothingToExport": "Nema GTIN brojeva za izvoz",
				"successfullyExport": "Uspesno ste izvezli podatke"
			},
			"nextAvailable": {
				"full": "Iskoristili ste sve GTIN brojeve za ovaj kompanijski prefiks"
			},
			"attributes": {
				"gtin": {
					"prompt": "GTIN"
				},
				"brand": {
					"prompt": "Brend"
				},
				"fullName": {
					"prompt": "Naziv proizvoda"
				},
				"netContent": {
					"prompt": "Neto količina"
				},
				"netContentUnit": {
					"prompt": "Jedinica mere"
				},
				"image": {
					"prompt": "Slika"
				},
				"labelDescription": {
					"prompt": "Opis za etiketu"
				},
				"mediumResolutionImage": {
					"prompt": "Slika srednje rezolucije"
				},
				"targetRegion": {
					"prompt": "Ciljno tržište"
				},
				"classificationSegment": {
					"prompt": "Segment"
				},
				"classificationFamily": {
					"prompt": "Familija"
				},
				"classificationClass": {
					"prompt": "Klasa"
				},
				"classificationBrick": {
					"prompt": "Brick"
				},
				"dataProviderGln": {
					"prompt": "Izvor podataka"
				},
				"tradeItemUnit": {
					"prompt": "Nivo pakovanja"
				},
				"language": {
					"prompt": "Jezik"
				},
				"deletionReason": {
					"prompt": "Molimo Vas, unesite razlog za brisanje"
				},
				"languageCode": {
					"prompt": "Šifra jezika"
				},
				"companyName": {
					"prompt": "Naziv kompanije"
				},
				"productClassification": {
					"prompt": "Klasifikacija proizvoda"
				},
				"status": {
					"prompt": "Status"
				},
				"gepirStatus": {
					"prompt": "Gepir status",
					"updated": "Uspešno izmenjen gepir status"
				},
				"action": {
					"prompt": "Akcija"
				},
				"view": {
					"prompt": "Pregled"
				},
				"edit": {
					"prompt": "Ažuriranje"
				},
				"copy": {
					"prompt": "Kopiranje"
				},
				"save": {
					"prompt": "Sačuvaj"
				},
				"cancel": {
					"prompt": "Otkaži"
				}

			},
			/*
   "status" : {
   	"active" : "Aktivan"
   },
   */
			"gepirStatus": {
				"active": "Izvezen",
				"pending": "Spreman za izvoz",
				"incomplete": "Nije spreman za izvoz"
			},
			"status": {
				"0": "",
				"1": "U pripremi",
				"2": "Raspoloživ",
				"3": "Ne proizvodi se",
				"4": "Obrisan"
			}
		},

		"eans": {
			"title": {
				"abbr": "EAN-8",
				"singular": "EAN-8 broj",
				"plural": "EAN-8 brojevi"
			},
			"list": {
				"loading": "EAN-8 brojevi se učitavaju..."
			},
			"gepirExport": {
				"nothingToExport": "Nema EAN-8 brojeva za izvoz"
			},
			"attributes": {
				"ean8": {
					"prompt": "EAN-8"
				},
				"brand": {
					"prompt": "Brend"
				},
				"fullName": {
					"prompt": "Naziv proizvoda"
				},
				"image": {
					"prompt": "Slika"
				},
				"labelDescription": {
					"prompt": "Opis za etiketu"
				},
				"mediumResolutionImage": {
					"prompt": "Slika srednje rezolucije"
				},
				"targetRegion": {
					"prompt": "Ciljno tržište"
				},
				"deletionReason": {
					"prompt": "Molimo Vas, unesite razlog za brisanje"
				},
				"classificationSegment": {
					"prompt": "Segment"
				},
				"classificationFamily": {
					"prompt": "Familija"
				},
				"classificationClass": {
					"prompt": "Klasa"
				},
				"classificationBrick": {
					"prompt": "Brick"
				},
				"netContent": {
					"prompt": "Neto količina"
				},
				"netContentUnit": {
					"prompt": "Jedinica mere"
				},
				"dataProviderGln": {
					"prompt": "Izvor podataka"
				},
				"tradeItemUnit": {
					"prompt": "Nivo pakovanja"
				},
				"language": {
					"prompt": "Jezik"
				},
				"languageCode": {
					"prompt": "Šifra jezika"
				},
				"companyName": {
					"prompt": "Naziv kompanije"
				},
				"productClassification": {
					"prompt": "Klasifikacija proizvoda"
				},
				"status": {
					"prompt": "Status"
				},
				"gepirStatus": {
					"prompt": "Gepir status"
				},
				"action": {
					"prompt": "Akcija"
				},
				"view": {
					"prompt": "Pregled"
				},
				"edit": {
					"prompt": "Ažuriranje"
				},
				"copy": {
					"prompt": "Kopiranje"
				},
				"save": {
					"prompt": "Sačuvaj"
				},
				"cancel": {
					"prompt": "Otkaži"
				}
			},
			/*
   "status" : {
   	"active" : "Aktivan"
   },
   */
			"gepirStatus": {
				"active": "Izvezen",
				"pending": "Spreman za izvoz",
				"incomplete": "U pripremi"
			},
			"status": {
				"0": "",
				"1": "U pripremi",
				"2": "Raspoloživ",
				"3": "Ne proizvodi se",
				"4": "Obrisan"
			}
		},

		"ui": {
			"table": {
				"empty": 'Lista je prazna'
			}
		}
	};
});