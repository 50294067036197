define('gs1-frontend/pods/components/gtins/-duplicate-gtin/component', ['exports', 'gs1-frontend/pods/components/modals/-modal/component', 'gs1-frontend/mixins/errorable'], function (exports, _component, _errorable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend(_errorable.default, {

		id: 'modalDuplicate',

		onCloseBound: false,

		gtin13Array: [{ value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }],

		gtin13ControlDigit: Ember.computed('gtin13Array.@each.value', function () {
			return this.calculateGtin13ControlDigit(this.get('gtin13Array').mapBy('value'));
		}),

		calculateGtin13ControlDigit: function calculateGtin13ControlDigit(gtin13Arr) {
			var sum = 0,
			    controlDigit = '',
			    gtinValue = '';
			for (var i = 0; i < gtin13Arr.length; i++) {
				sum += (i % 2 ? 1 : 3) * parseInt(gtin13Arr.objectAt(gtin13Arr.length - 1 - i));
			}
			controlDigit = (10 - sum % 10) % 10;
			gtin13Arr.forEach(function (digit) {
				gtinValue += digit;
			});
			gtinValue += controlDigit;
			this.set('currentGtinValue', gtinValue);
			return '' + controlDigit ? controlDigit : 'x';
		},
		fillGtin13Array: function fillGtin13Array() {
			var self = this;
			self.set('gtin.mediumResImageToken', 'duplicate');
			self.set('gtin.status', '1');

			if (self.get('gtin.gtinValue')) {
				var value = self.get('gtin.gtinValue');
				self.get('gtin13Array').forEach(function (digit, index) {
					Ember.set(digit, 'value', value[index]);
					Ember.set(digit, 'editable', false);
				});
			}
		},


		formFields: Ember.computed(function () {
			return this.get('store').query('form-field', {
				'filter[field]': 'gtin,sale_country_id,brick_id,segment_id,class_id,family_id,brand_name,full_name,language,trade_item_unit_id,product_status_id,data_provider_gln'
			});
		}),

		fieldHelpContent: Ember.computed('formFields.[]', function () {
			var arr = {};
			this.get('formFields').forEach(function (item, index) {
				arr[item.get('field')] = item.get('help_text');
			});
			return arr;
		}),

		allCountries: Ember.computed(function () {
			return this.get('store').findAll('country');
		}),

		selectedCountry: '',

		selectedCountryObserver: Ember.observer('selectedCountry', function () {

			var country = this.get('allCountries').findBy('id', this.get('selectedCountry').toString());
			if (country) {
				this.get('gtin').set('targetCountry', country);
			} else {
				this.get('gtin').set('targetCountry', null);
			}
			console.log(this.get('gtin'));
		}),

		allLanguages: Ember.computed(function () {
			return this.get('store').findAll('language');
		}),

		selectedLanguage: '',

		selectedLanguageObserver: Ember.observer('selectedLanguage', function () {
			var language = this.get('allLanguages').findBy('id', this.get('selectedLanguage'));
			if (language) {
				this.get('gtin').set('language', language);
			} else {
				this.get('gtin').set('language', null);
			}
		}),

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);

			var element = Ember.$(this.get('element'));

			element.on('click', function (e) {
				var target = Ember.$(e.target);
				var targetHelp = target.closest('.help');
				if (!targetHelp.length) {
					element.find('.help-content').hide();
				} else {
					if (target.closest('.question-mark').length) {
						targetHelp.find('.help-content').show();
					} else if (target.closest('.help-close').length) {
						targetHelp.find('.help-content').hide();
					}

					element.find('.help').not(targetHelp).find('.help-content').hide();
				}
			});
		},
		didReceiveAttrs: function didReceiveAttrs() {
			var currentMemberId = this.get('companyPrefix.member.id');
			if (currentMemberId) {
				if (this.get('gtin') && !this.get('gtin.id')) {
					this.set('selectedMember', currentMemberId);
					this.notifyPropertyChange('selectedMember');
				}
			}
		},
		didRender: function didRender() {
			var self = this;

			if (!self.get('onCloseBound')) {

				var onClose = self.get('onClose');
				if (typeof onClose === 'function') {
					Ember.$(self.get('element')).on('hide.bs.modal', onClose);
				}

				Ember.$(self.get('element')).on('hide.bs.modal', function () {
					self.resetErrors();
				});

				self.set('selectedCountry', '');
				self.set('imageUploading', false);
				self.set('imageUploaded', false);
				self.set('onCloseBound', true);
			}

			if (!self.get('onShowBound')) {
				Ember.$(self.get('element')).on('show.bs.modal', function () {
					var gtin = self.get('gtin');
					if (gtin) {

						var language;
						gtin.get('language').then(function (obtainedLanguage) {
							if (obtainedLanguage && obtainedLanguage.get('id')) {
								language = obtainedLanguage;
							} else {
								language = self.get('allLanguages').filterBy('isDefault', true).objectAt(0);
							}
						}).finally(function () {
							self.set('selectedLanguage', language ? language.get('id') : '');
							self.notifyPropertyChange('selectedLanguage');
						});

						self.fillGtin13Array();
					}
				});

				self.set('onShowBound', true);
			}

			Ember.$(self.get('element')).find('#duplicate-gtin-image-input').fileTransport({
				start: function start() {
					self.set('imageUploading', true);
					self.set('imageUploaded', false);
					self.set('imageError', null);
				},
				success: function success(event, file, token, meta, data) {
					self.set('imageUploading', false);
					self.set('imageUploaded', true);
					self.set('imageError', null);
					self.set('gtin.mediumResImage', meta.thumbnail);
					self.set('gtin.mediumResImageToken', token);
				},
				error: function error(event, id, message) {
					self.set('imageError', message);
					self.set('imageUploading', false);
					self.set('imageUploaded', false);
				}
			});
		},


		actions: {
			removeImage: function removeImage() {
				this.set('gtin.mediumResImageToken', null);
				this.set('gtin.mediumResImage', null);
			},
			saveGtin: function saveGtin() {
				var self = this;
				var onSave = self.get('onSave');
				if (typeof onSave === 'function') {
					onSave().then(function () {
						self.closeModal();
					}).catch(function (response) {
						self.handleErrors(response.errors);
					}).finally(function () {
						//self.closeModal();
					});
				}
			}
		}

	});
});