define('gs1-frontend/pods/member-registration/index/route', ['exports', 'gs1-frontend/mixins/errorable'], function (exports, _errorable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_errorable.default, {
		setRetrievedCompanyData: function setRetrievedCompanyData(data) {
			var self = this;

			var member = self.get('controller.model.member');

			if (data.member_type == 1) {
				member.set('memberType', 1);
				member.set('name', data.short_name);
				member.set('fullBusinessName', data.company);
				member.set('address', data.addressCompany);
				member.set('zip', data.postal_code);
				member.set('city', data.city);
				member.set('municipality', data.municipality);
				member.set('email', data.email);
				member.set('telephone', data.phone);
				member.set('crn', data.mb);
				member.set('tin', data.pib);

				console.log("Got here");

				member.get('bankAccounts').then(function (bankAccounts) {
					console.log(bankAccounts.length);
				});

				if (data.bank_accounts) {
					/*
     for (var i=0; i<data.bank_accounts.length; i++) {
     	self.get('store').createRecord('bank-account', {
     		bankName: data.bank_accounts[i].bank_name,
     		accountNumber: data.bank_accounts[i].account_number,
     		member: member
     	});
     }
     */
					self.set('controller.model.companyBankAccounts', []);
					for (var i = 0; i < data.bank_accounts.length; i++) {
						self.get('controller.model.companyBankAccounts').pushObject(data.bank_accounts[i]);
					}
				}
			} else {
				member.set('memberType', 2);
				member.set('name', data.company_name);
				member.set('jmbg', data.jmbg);
				member.set('bpg', data.bpg);
				member.set('email', data.email);
				member.set('telephone', data.phone);
				member.set('ownerName', data.name);
			}
		},


		actions: {
			handleSuccess: function handleSuccess(response) {
				console.log("It was successfull");
				this.setRetrievedCompanyData(response.data);
				this.transitionTo('member-registration.step-1');
			}
		}
	});
});