define('gs1-frontend/pods/gtin-list-loading/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_authenticatedRouteMixin.default, {
		setupController: function setupController(controller /*, model*/) {
			var mainController = this.controllerFor('gtin-list');

			// Copy the search parameters
			controller.set('termBuffer', mainController.get('termBuffer'));
			controller.set('status', mainController.get('status'));
			controller.set('gepirStatus', mainController.get('gepirStatus'));
			controller.set('classificationSegment', mainController.get('classificationSegment'));
			controller.set('classificationFamily', mainController.get('classificationFamily'));
			controller.set('classificationClass', mainController.get('classificationClass'));
			controller.set('classificationBrick', mainController.get('classificationBrick'));
			controller.set('selectedSegment', mainController.get('selectedSegment'));
			controller.set('selectedFamily', mainController.get('selectedFamily'));
			controller.set('selectedClass', mainController.get('selectedClass'));
			controller.set('allClassificationSegments', mainController.get('allClassificationSegments'));
			controller.set('allClassificationFamilies', mainController.get('allClassificationFamilies'));
			controller.set('allClassificationClasses', mainController.get('allClassificationClasses'));
			controller.set('allClassificationBricks', mainController.get('allClassificationBricks'));

			// Show loading animation
			controller.set('loading', true);
		}
	});
});