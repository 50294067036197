define('gs1-frontend/pods/member-registration/step-5/controller', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Controller = Ember.Controller;
	exports.default = Controller.extend({

		medicalObserver: Ember.observer('model.member.medicalAny', function () {
			this.set('model.member.medicalPharmaceuticals', null);
			this.set('model.member.medicalInstruments', null);
			this.set('model.member.medicalRemedies', null);
		})

	});
});