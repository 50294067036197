define('gs1-frontend/models/net-content-unit', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		type: _emberData.default.attr('string'),
		unitLabel: _emberData.default.attr('string'),
		unitName: _emberData.default.attr('string')

	});
});