define('gs1-frontend/pods/members/index/route', ['exports', 'gs1-frontend/mixins/pageable-route'], function (exports, _pageableRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_pageableRoute.default, {

		queryParams: {
			term: {
				refreshModel: true,
				replace: true
			},
			activityCode: {
				refreshModel: true,
				replace: true
			},
			status: {
				refreshModel: true,
				replace: true
			}
		},

		model: function model(params) {
			var options = {};

			if (!Ember.isEmpty(params.term)) {
				if (options.filter) {
					options.filter.term = params.term;
				} else {
					options.filter = {
						term: params.term
					};
				}
			}

			if (!Ember.isEmpty(params.activityCode)) {
				if (options.filter) {
					options.filter.activity_code = params.activityCode;
				} else {
					options.filter = {
						activity_code: params.activityCode
					};
				}
			}

			if (!Ember.isEmpty(params.status)) {
				if (options.filter) {
					options.filter.status = params.status;
				} else {
					options.filter = {
						status: params.status
					};
				}
			}

			return this.pageableModel('member', 'members', options, params);
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			setTimeout(function () {
				var field = Ember.$('#members-search-by-term');
				if (field.val().length) field.focus();
			}, 100);

			if (Ember.isEmpty(controller.get('termBuffer')) && !Ember.isEmpty(controller.get('term'))) {
				controller.set('termBuffer', controller.get('term'));
			}
		}
	});
});