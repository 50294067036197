define('gs1-frontend/app', ['exports', 'gs1-frontend/resolver', 'ember-load-initializers', 'gs1-frontend/config/environment', 'gs1-frontend/models/custom-inflector-rules'], function (exports, _resolver, _emberLoadInitializers, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Application = Ember.Application;


  Ember.onerror = function (error) {
    console.error('Ember error', error);
  };

  Ember.RSVP.on('error', function (error) {
    console.error('RSVP error', error);
  });

  window.onerror = function (message, source, lineno, colno, error) {
    console.log('Window registered an error');
  };

  var App = Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});