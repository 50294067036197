define('gs1-frontend/pods/components/main/-nav/component', ['exports', 'gs1-frontend/pods/components/-base/base-component/component'], function (exports, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend({

		classNames: ['main-header'],
		tagName: 'header',

		actions: {
			logout: function logout() {
				this.get('session').invalidate();
			}
		}

	});
});