define('gs1-frontend/pods/components/ui/-equal-height-elements/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		didInsertElement: function didInsertElement() {
			var selector = this.get('selector') ? this.get('selector') : null;

			var targets = selector ? Ember.$(this.get('element')).find(selector) : Ember.$(this.get('element')).children();

			targets.matchHeight({ remove: true }).matchHeight();
		}
	});
});