define('gs1-frontend/models/custom-inflector-rules', ['exports', 'ember-inflector'], function (exports, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var inflector = _emberInflector.default.inflector;

  inflector.irregular('ean8', 'ean8');
  //inflector.uncountable('advice');

  exports.default = {};
});