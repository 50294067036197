define("gs1-frontend/pods/index/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_authenticatedRouteMixin.default, {
		afterModel: function afterModel() {
			var _this = this;

			Ember.run.later(function () {
				var user = _this.get("currentUser.user");

				// If the user is an operator of the member, load that page
				if (user.get("member.id") !== null && user.get("privileges").toArray().indexOf("operator") > -1) {
					_this.router.transitionTo("/members/" + user.get("member.id"));
					return;
				}

				// List the members
				_this.router.transitionTo("/members");
			}, 2000);
		}
	});
});