define('gs1-frontend/models/contact-person', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		name: _emberData.default.attr('string'),
		type: _emberData.default.attr('string'),
		email: _emberData.default.attr('string'),
		phone: _emberData.default.attr('string'),

		company: _emberData.default.belongsTo('member')
	});
});