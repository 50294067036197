define('gs1-frontend/helpers/member-status-to-color', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.memberStatusToColor = memberStatusToColor;
	var helper = Ember.Helper.helper;
	function memberStatusToColor(params /*, hash*/) {
		params = parseInt(params, 10);
		var colors = ['green', 'orange', 'light-blue', 'green', 'grey', 'dark', 'blue'];
		if (params > 0 && params <= colors.length) {
			return colors[params - 1];
		} else {
			return 'unknown';
		}
	}

	exports.default = helper(memberStatusToColor);
});