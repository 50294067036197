define('gs1-frontend/pods/member-registration/step-2/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		beforeModel: function beforeModel() {
			var member = Ember.get(this.modelFor('member-registration'), 'member');
			if (Ember.isEmpty(member) || Ember.isEmpty(Ember.get(member, 'memberType')) || Ember.get(member, 'memberType') == 1 && Ember.isEmpty(Ember.get(member, 'crn')) || Ember.get(member, 'memberType') == 2 && Ember.isEmpty(Ember.get(member, 'jmbg'))) {
				this.transitionTo('member-registration');
			}
		},


		actions: {
			moveForward: function moveForward() {
				this.transitionTo('member-registration.step-3');
			},
			goBack: function goBack() {
				this.transitionTo('member-registration.step-1');
			}
		}
	});
});