define('gs1-frontend/router', ['exports', 'gs1-frontend/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('not-found', { path: '/*path' });

    this.route('login');

    this.route('members', function () {
      this.route('single', { path: '/:member_id' }, function () {
        this.route('documents');
        this.route('company-prefixes', function () {
          this.route('single', { path: '/:company_prefix_id' }, function () {
            this.route('gtins', function () {
              this.route('single', { path: '/:gtin_id' });
              this.route('index-loading');
            });
          });
        });
        this.route('ean8-numbers');
      });
      this.route('index-loading');
    });

    this.route('gtin-list');

    this.route('application-loading');
    this.route('gtin-list-loading');
    this.route('member-registration', function () {
      this.route('step-1');
      this.route('step-2');
      this.route('step-3');
      this.route('step-4');
      this.route('step-5');
      this.route('confirmation');
    });
  });

  exports.default = Router;
});