define('gs1-frontend/pods/application/route', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'gs1-frontend/mixins/eventable'], function (exports, _applicationRouteMixin, _eventable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	var EmberObject = Ember.Object;
	var computed = Ember.computed;
	var observer = Ember.observer;
	exports.default = Route.extend(_eventable.default, _applicationRouteMixin.default, {

		// Ensuring that we stay on the same page if we are not logged in and then we log in with a popup
		// routeAfterAuthentication: null,

		subscriptions: function subscriptions() {
			return [{ event: 'gs1:adapterError', handler: this.get('adapterHandler') }];
		},


		currentUser: Ember.inject.service('current-user'),

		beforeModel: function beforeModel() {
			return this._loadCurrentUser();
		},
		sessionAuthenticated: function sessionAuthenticated() {
			this._super.apply(this, arguments);
			this._loadCurrentUser();
		},
		_loadCurrentUser: function _loadCurrentUser() {
			var _this = this;

			return this.get('currentUser').load().catch(function () {
				//console.log('catching error');
				_this.get('session').invalidate();
			});
		},
		init: function init() {
			this._super.apply(this, arguments);

			var self = this;

			Ember.$(window).on('resize', function () {
				self.publish("gs1:screenSizeChanged");
			});
		},


		// setupController(controller, model) {
		// 	console.log('Entering controller setup for application');
		// 	this._super(...arguments);
		// 	this.set('activeMember', null);
		// },

		adapterHandler: function adapterHandler(status, response, requestData) {
			console.log('Detected adapter error');
			if (status == 403) {
				alert('Token is invalid, logging out!');
				this.get('session').invalidate();
			}
		},


		actions: {
			error: function error(_error, transition) {
				console.log('Application caught an error', _error);
				this.publish('gs1:newGlobalError', _error);
			}
		}
	});
});