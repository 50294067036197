define('gs1-frontend/pods/members/single/ean8-numbers/route', ['exports', 'gs1-frontend/config/environment', 'gs1-frontend/mixins/eventable', 'ember-simple-auth/mixins/authenticated-route-mixin', 'gs1-frontend/mixins/pageable-route'], function (exports, _environment, _eventable, _authenticatedRouteMixin, _pageableRoute) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend(_pageableRoute.default, _authenticatedRouteMixin.default, _eventable.default, {

		queryParams: {
			status: {
				refreshModel: true,
				replace: true
			},
			term: {
				refreshModel: true,
				replace: true
			},
			classificationSegment: {
				refreshModel: true,
				replace: true
			},
			classificationFamily: {
				refreshModel: true,
				replace: true
			},
			classificationClass: {
				refreshModel: true,
				replace: true
			},
			classificationBrick: {
				refreshModel: true,
				replace: true
			}
		},

		i18n: Ember.inject.service(),

		getRidOfCurrentEan8: function getRidOfCurrentEan8() {
			var currentEan8 = this.get('controller.currentEan8');
			if (currentEan8) {
				if (!currentEan8.get('id')) {
					currentEan8.destroyRecord();
				} else {
					currentEan8.rollbackAttributes();
				}
				this.set('controller.currentEan8', null);
			}
		},
		model: function model(params) {
			var options = {
				filter: {
					member: this.modelFor('members.single').id
				}
			};

			if (!Ember.isEmpty(params.status)) {
				if (options.filter) {
					options.filter.status = params.status;
				} else {
					options.filter = {
						status: params.status
					};
				}
			}

			if (!Ember.isEmpty(params.term)) {
				if (options.filter) {
					options.filter.term = params.term;
				} else {
					options.filter = {
						term: params.term
					};
				}
			}

			if (!Ember.isEmpty(params.classificationSegment)) {
				if (options.filter) {
					options.filter.classification_segment_id = params.classificationSegment;
				} else {
					options.filter = {
						classification_segment_id: params.classificationSegment
					};
				}
			}

			if (!Ember.isEmpty(params.classificationFamily)) {
				if (options.filter) {
					options.filter.classification_family_id = params.classificationFamily;
				} else {
					options.filter = {
						classification_family_id: params.classificationFamily
					};
				}
			}

			if (!Ember.isEmpty(params.classificationClass)) {
				if (options.filter) {
					options.filter.classification_class_id = params.classificationClass;
				} else {
					options.filter = {
						classification_class_id: params.classificationClass
					};
				}
			}

			if (!Ember.isEmpty(params.classificationBrick)) {
				if (options.filter) {
					options.filter.classification_brick_id = params.classificationBrick;
				} else {
					options.filter = {
						classification_brick_id: params.classificationBrick
					};
				}
			}

			return this.pageableModel('ean8', 'eans', options, params);
		},
		sendDeleteRequest: function sendDeleteRequest(ean8, deletionReason) {
			var self = this;

			var promise = new Ember.RSVP.Promise(function (resolve, reject) {
				Ember.$.ajax({
					type: 'POST',
					url: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/ajax/delete_ean8',
					data: {
						ean8: ean8.get('ean8Value'),
						comment: deletionReason
					},
					headers: {
						'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
					},
					success: function success(response) {
						console.log(response);
						resolve(response);
						/*
      if (response.next_available_gtin) {
      	controller.set('nextAvailableGtin', response.next_available_gtin);
      }
      else {
      	self.publish('gs1:newGlobalMessage', {
      		type: 'success',
      		text: self.get('i18n').t('gtins.nextAvailable.full')
      	});
      }
      */
					},
					error: function error(jqXHR, status, _error) {
						console.log('Cvrc ' + status);
						reject(jqXHR.responseJSON);
						/*
      self.publish('gs1:newGlobalError', {
      	text: status
      });
      */
					}
				});
			});

			return promise;

			/*
   Ember.$.ajax({
   	type: 'POST',
   	url: ENV.gs1.serverEndpoint + '/' + ENV.gs1.apiNamespace + '/ajax/delete',
   	data: {
   		gtin: '0896786',
   		comment: 'Lalalalala'
   	},
   	headers: {
   		'Authorization': 'Bearer ' + self.get('session.data.authenticated.access_token')
   	},
   	success: (response) => {
   		console.log(response);
   		if (response.next_available_gtin) {
   			controller.set('nextAvailableGtin', response.next_available_gtin);
   		}
   		else {
   			self.publish('gs1:newGlobalMessage', {
   				type: 'success',
   				text: self.get('i18n').t('gtins.nextAvailable.full')
   			});
   		}
   	},
   	error: (jqXHR, status, error) => {
   		console.log('Cvrc ' + status);
   		self.publish('gs1:newGlobalError', {
   			text: status
   		});
   	}
   });
   */
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);

			if (Ember.isEmpty(controller.get('termBuffer')) && !Ember.isEmpty(controller.get('term'))) {
				controller.set('termBuffer', controller.get('term'));
			}

			var member = this.modelFor('members.single');

			controller.set('member', member);
		},


		duplicate: function duplicate(oldObject) {
			var newObject = oldObject.toJSON();
			for (var key in newObject) {
				if (newObject[key] != oldObject.get(key)) {
					newObject[key] = oldObject.get(key);
				}
			}
			newObject.id = null;
			delete newObject.id;
			return newObject;
		},

		actions: {
			clearCurrentEan8: function clearCurrentEan8() {
				this.getRidOfCurrentEan8();
			},
			saveEan8: function saveEan8() {
				var self = this;
				if (!self.get('controller.currentEan8')) {
					//console.error('Nothing to save');
					return Ember.RSVP.reject();
				} else {
					self.set('controller.currentEan8.member', self.modelFor('members.single'));
					self.set('controller.currentEan8.companyPrefix', self.modelFor('members.single.company-prefixes.single'));
					return self.get('controller.currentEan8').save().then(function (ean8) {
						self.refresh();
						return Ember.RSVP.resolve(ean8);
					}).catch(function (errors) {
						return Ember.RSVP.reject(errors);
					});
				}
			},
			editNewEan8: function editNewEan8() {
				this.getRidOfCurrentEan8();
				this.set('controller.currentEan8', this.get('store').createRecord('ean8'));
			},
			editEan8: function editEan8(ean8) {
				this.getRidOfCurrentEan8();
				this.set('controller.currentEan8', ean8);
			},
			viewEan8: function viewEan8(ean8) {
				this.getRidOfCurrentEan8();
				this.set('controller.currentEan8', ean8);
			},
			duplicateEan8: function duplicateEan8(ean8) {
				this.getRidOfCurrentEan8();
				this.set('controller.cloneEan8', ean8);
				this.set('controller.currentEan8', this.get('store').createRecord('ean8', this.duplicate(ean8)));
			},
			showDeleteEan8: function showDeleteEan8(ean8) {
				this.getRidOfCurrentEan8();
				this.set('controller.currentEan8', ean8);
			},
			deleteEan8: function deleteEan8(deletionReason) {
				var self = this;
				var currentEan8 = self.get('controller.currentEan8');
				if (!currentEan8) {
					console.error('Nothing to delete');
					return Ember.RSVP.reject();
				} else {
					return self.sendDeleteRequest(currentEan8, deletionReason);

					return self.get('controller.currentEan8').save().then(function (ean8) {
						self.refresh();
						return Ember.RSVP.resolve(ean8);
					}).catch(function (errors) {
						return Ember.RSVP.reject(errors);
					});
				}
			}
		}
	});
});