define('gs1-frontend/pods/member/serializer', ['exports', 'ember-data', 'ember-data-save-relationships'], function (exports, _emberData, _emberDataSaveRelationships) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.JSONAPISerializer.extend(_emberDataSaveRelationships.default, {
		keyForAttribute: function keyForAttribute(attr) {
			return Ember.String.underscore(attr);
		},
		keyForRelationship: function keyForRelationship(attr) {
			return Ember.String.underscore(attr);
		},


		attrs: {
			bankAccounts: { serialize: true }
		}

	});
});