define('gs1-frontend/models/classification-class', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		name: _emberData.default.attr('string'),
		code: _emberData.default.attr('string'),

		classificationFamily: _emberData.default.belongsTo('classification-family'),
		classificationBricks: _emberData.default.hasMany('classification-brick')
	});
});