define('gs1-frontend/pods/components/registration/-basic-info-form/component', ['exports', 'gs1-frontend/config/environment', 'gs1-frontend/pods/components/-base/base-component/component', 'gs1-frontend/mixins/errorable'], function (exports, _environment, _component, _errorable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend(_errorable.default, {

		actions: {
			sendBasicForm: function sendBasicForm() {
				var self = this;

				var dataToSend = {};
				if (self.get('typeOfMember') == 1) {
					dataToSend.member_type = 1;
					dataToSend.company_name = self.get('companyName');
					dataToSend.mb = self.get('registrationNumber');
					dataToSend.pib = self.get('taxNumber');
					dataToSend.person_name = self.get('personName');
					dataToSend.contact_phone = self.get('contactPhone');
					dataToSend.contact_email = self.get('contactEmail');
				} else if (self.get('typeOfMember') == 2) {
					dataToSend.member_type = 2;
					dataToSend.company_name = self.get('companyName');
					dataToSend.jmbg = self.get('personalNumber');
					dataToSend.bpg = self.get('agroNumber');
					dataToSend.person_name = self.get('personName');
					dataToSend.contact_phone = self.get('contactPhone');
					dataToSend.contact_email = self.get('contactEmail');
				} else {
					return;
				}

				Ember.$.ajax({
					type: 'POST',
					url: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/ajax/registration',
					data: dataToSend,
					success: function success(response) {
						console.log(response);
						var onSuccess = self.get('onSuccess');
						if (typeof onSuccess == 'function') {
							self.resetErrors();
							onSuccess(response);
						}
					},
					error: function error(jqXHR, status, response) {
						var response = jqXHR.responseJSON;
						if (response && response.errors) {
							self.handleErrors(response.errors);
						} else {
							alert('Unknown error');
						}
					}
				});
			}
		}

	});
});