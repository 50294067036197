define('gs1-frontend/pods/components/auth/-login/component', ['exports', 'gs1-frontend/pods/components/-base/base-component/component'], function (exports, _component) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend({

		actions: {
			login: function login() {
				var self = this;

				var _getProperties = this.getProperties('username', 'password'),
				    username = _getProperties.username,
				    password = _getProperties.password;

				self.set('waitingForServer', true);
				self.get('session').authenticate('authenticator:oauth2', username, password).then(function () {
					self.set('error', null);
				}).catch(function (response) {
					console.log("An error caught while logging in", response);
					if (typeof response.errors !== 'undefined' && response.errors.length && response.errors[0].title) {
						self.set('error', { message: response.errors[0].title });
					} else {
						self.get('session').invalidate();
					}
				});
			}
		}

	});
});