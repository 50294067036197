define('gs1-frontend/models/member', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		name: _emberData.default.attr('string'),
		fullBusinessName: _emberData.default.attr('string'),
		ownerName: _emberData.default.attr('string'),
		email: _emberData.default.attr('string'),
		url: _emberData.default.attr('string'),
		telephone: _emberData.default.attr('string'),
		cellphone: _emberData.default.attr('string'),
		status: _emberData.default.attr('string'),
		masterGlnNumber: _emberData.default.attr('string'),
		account: _emberData.default.attr('string'),
		bankName: _emberData.default.attr('string'),
		contactPersonGs1: _emberData.default.attr('string'),
		address: _emberData.default.attr('string'),
		crn: _emberData.default.attr('string'),
		tin: _emberData.default.attr('string'),
		jmbg: _emberData.default.attr('string'),
		bpg: _emberData.default.attr('string'),
		sd: _emberData.default.attr('string'),
		accountingCode: _emberData.default.attr('string'),
		city: _emberData.default.attr('string'),
		zip: _emberData.default.attr('string'),
		country: _emberData.default.attr('string'),
		logoUrl: _emberData.default.attr('string'),
		numberRange: _emberData.default.attr('number'),
		medicalAny: _emberData.default.attr('boolean'),
		medicalPharmaceutical: _emberData.default.attr('boolean'),
		medicalInstruments: _emberData.default.attr('boolean'),
		medicalRemedies: _emberData.default.attr('boolean'),
		nameDelivery: _emberData.default.attr('string'),
		addressDelivery: _emberData.default.attr('string'),
		zipDelivery: _emberData.default.attr('string'),
		cityDelivery: _emberData.default.attr('string'),
		memberType: _emberData.default.attr('number'),
		bankAccount: _emberData.default.attr('string'),

		// Temporary attributes
		tmpCpTransferCode: _emberData.default.attr('string'),

		// Relationships
		companyPrefixes: _emberData.default.hasMany('company-prefix'),
		gtins: _emberData.default.hasMany('gtin', { inverse: 'member' }),
		eans: _emberData.default.hasMany('ean8', { inverse: 'member' }),
		contactPeople: _emberData.default.hasMany('contact-person'),
		activityCode: _emberData.default.belongsTo('activity-code'),
		bankAccounts: _emberData.default.hasMany('bank-account')
	});
});