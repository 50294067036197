define('gs1-frontend/models/form-field', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		model: _emberData.default.attr('string'),
		field: _emberData.default.attr('string'),
		field_name: _emberData.default.attr('string'),
		required: _emberData.default.attr('number'),
		validation_rule: _emberData.default.attr('string'),
		validation_message: _emberData.default.attr('string'),
		help_text: _emberData.default.attr('string')

	});
});