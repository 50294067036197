define('gs1-frontend/pods/components/-base/base-component/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend( /*Eventable, Preloadable,*/{

		session: Ember.inject.service('session'),

		store: Ember.inject.service('store'),

		routing: Ember.inject.service('-routing'),

		currentUser: Ember.inject.service('current-user')

		// config: Ember.inject.service('config')

	});
});