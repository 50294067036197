define('gs1-frontend/pods/components/gtins/-delete-gtin/component', ['exports', 'gs1-frontend/pods/components/modals/-modal/component', 'gs1-frontend/mixins/errorable', 'gs1-frontend/mixins/eventable'], function (exports, _component, _errorable, _eventable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend(_errorable.default, _eventable.default, {

		id: 'modalDeleteGtin',

		deletionReason: '',

		actions: {
			deleteGtin: function deleteGtin() {
				var self = this;

				var onDelete = self.get('onDelete');
				if (typeof onDelete === 'function') {
					onDelete(self.get('deletionReason')).then(function (response) {
						//console.log('Successful');
						self.closeModal();
						self.publish('gs1:newGlobalMessage', {
							type: 'success',
							text: response.message
						});
					}).catch(function (response) {
						self.handleErrors(response.errors);
					}).finally(function () {
						//self.closeModal();
					});
				}
			}
		}

	});
});