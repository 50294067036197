define('gs1-frontend/mixins/eventable', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Mixin = Ember.Mixin;
	exports.default = Mixin.create({
		messageBus: Ember.inject.service('message-bus'),

		subscriptions: function subscriptions() {
			return [];
		},
		publish: function publish() {
			var _get;

			(_get = this.get('messageBus')).publish.apply(_get, arguments);
		},
		init: function init() {
			this._super.apply(this, arguments);

			var self = this;
			var messageBus = self.get('messageBus');

			self.subscriptions().forEach(function (item) {
				messageBus.subscribe(item.event, self, item.handler);
			});
		}
	});
});