define('gs1-frontend/pods/member-registration/step-3/route', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Route = Ember.Route;
	exports.default = Route.extend({
		beforeModel: function beforeModel() {
			var member = Ember.get(this.modelFor('member-registration'), 'member');
			if (Ember.isEmpty(member) || Ember.isEmpty(Ember.get(member, 'memberType')) || Ember.get(member, 'memberType') == 1 && Ember.isEmpty(Ember.get(member, 'crn')) || Ember.get(member, 'memberType') == 2 && Ember.isEmpty(Ember.get(member, 'jmbg'))) {
				this.transitionTo('member-registration');
			}
		},
		setupController: function setupController(controller, model) {
			this._super.apply(this, arguments);
			/*
   if (Ember.isEmpty(Ember.get(model, 'member.bankAccounts'))) {
   	var temp = this.addEmptyAccount();
   	temp.destroyRecord();
   }
   */
		},
		addEmptyAccount: function addEmptyAccount() {
			var member = this.get('controller.model.member');
			var newAccount = this.get('store').createRecord('bank-account', {
				member: member
			});
			return newAccount;
			//newAccount.destroyRecord();
		},


		actions: {
			moveForward: function moveForward() {
				this.transitionTo('member-registration.step-4');
			},
			goBack: function goBack() {
				this.transitionTo('member-registration.step-2');
			},
			addAccount: function addAccount() {
				this.addEmptyAccount();
			}
		}
	});
});