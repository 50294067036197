define("gs1-frontend/pods/components/ui/-loading/component", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({

		// The class name for the main div
		classNames: ["empty-content"],

		// The message to show
		message: null

	});
});