define('gs1-frontend/pods/components/ui/-global-messenger/component', ['exports', 'gs1-frontend/mixins/eventable'], function (exports, _eventable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend(_eventable.default, {

		classNames: ['global-message-page-holder'],

		messages: [],

		subscriptions: function subscriptions() {
			return [{ event: "gs1:sidebarWidth", handler: this.handleSidebarWidth }, { event: "gs1:newGlobalMessage", handler: this.newGlobalMessage }, { event: "gs1:newGlobalError", handler: this.newGlobalError }, { event: "gs1:newGlobalErrors", handler: this.newGlobalErrors }];
		},
		handleSidebarWidth: function handleSidebarWidth(width) {
			Ember.$(this.get('element')).css('margin-left', width / 2);
		},
		newGlobalMessage: function newGlobalMessage(message) {
			var self = this;

			self.get('messages').insertAt(0, message);
			setTimeout(function () {
				self.$('.global-message-page:not(.animation)').addClass('animation');
			}, 50);
		},
		newGlobalError: function newGlobalError(error) {
			var self = this;

			var text = error.title || error.message;

			self.newGlobalMessage({
				type: 'error',
				text: text
			});
		},
		newGlobalErrors: function newGlobalErrors(errors) {
			var self = this;
			errors.forEach(function (error) {
				self.newGlobalError(error);
			});
		},


		actions: {
			clearMessage: function clearMessage(message) {
				this.get('messages').removeObject(message);
			}
		}
	});
});