define('gs1-frontend/mixins/errorable', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Mixin = Ember.Mixin;
	exports.default = Mixin.create({

		errors: [],

		resetErrors: function resetErrors() {
			this.set('errors', []);
		},
		handleErrors: function handleErrors(errors) {
			var self = this;

			if (!errors || !errors.length) {
				self.set('errors', []);
			} else {
				self.set('errors', []);
				errors.forEach(function (error) {
					if (Ember.get(error, 'code') == 'validation') {
						// validation
						var pointerTrace = Ember.get(error, 'source') ? Ember.get(error, 'source').split('/') : [];
						var pointer = pointerTrace.length ? pointerTrace.get('lastObject') : null;
						self.get('errors').pushObject({
							field: pointer.camelize(),
							error: error
						});
					} else if (Ember.get(error, 'code') == 'message') {
						self.get('errors').pushObject({
							field: 'overall',
							error: error
						});
					} else {
						self.get('errors').pushObject({
							field: null,
							error: error
						});
					}
				});
			}
		}
	});
});