define('gs1-frontend/helpers/member-status-to-text', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.memberStatusToText = memberStatusToText;
	var helper = Ember.Helper.helper;
	function memberStatusToText(params /*, hash*/) {
		params = parseInt(params, 10);
		var names = ['active', 'inJoiningProcess', 'registered', 'left', 'excluded', 'inActivationProcess'];
		var translationPrefix = 'members.status.';
		if (params > 0 && params <= names.length) {
			return translationPrefix + names[params - 1];
		} else {
			return translationPrefix + 'unknown';
		}
	}

	exports.default = helper(memberStatusToText);
});