define('gs1-frontend/pods/components/ui/-pagination/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	exports.default = Component.extend({
		classNames: ['clearfix', 'pagination-component'],

		numberArray: Ember.computed('currentPage', 'totalPages', function () {
			var arr = [],
			    current = this.get('currentPage'),
			    totalPages = this.get('totalPages');

			arr.pushObject(current);
			if (current > 1) {
				arr.insertAt(0, current - 1);
			}
			if (current < totalPages) {
				arr.pushObject(current + 1);
			}

			if (arr.objectAt(0) > 1) {
				arr.insertAt(0, 1);
				if (arr.objectAt(1) > 2) {
					arr.insertAt(1, -1);
				}
			}

			if (arr.objectAt(arr.length - 1) < totalPages) {
				arr.pushObject(totalPages);
				if (arr.objectAt(arr.length - 2) < totalPages - 1) {
					arr.insertAt(arr.length - 1, -1);
				}
			}

			return arr;
		})
	});
});