define('gs1-frontend/pods/components/main/-sidebar/component', ['exports', 'gs1-frontend/pods/components/-base/base-component/component', 'gs1-frontend/mixins/eventable'], function (exports, _component, _eventable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend(_eventable.default, {

    classNames: ['main-sidebar'],
    tagName: 'aside',

    handleWindowSize: function handleWindowSize() {
      var width = Ember.$(this.get('element')).width();
      this.publish('gs1:sidebarWidth', width);
    },
    didInsertElement: function didInsertElement() {
      var self = this;

      self.handleWindowSize();
      Ember.$(window).on('resize', function () {
        self.handleWindowSize();
      });
    }
  });
});