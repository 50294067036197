define('gs1-frontend/models/ean8', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({

		// Attributes
		ean8Value: _emberData.default.attr('string'),
		brand: _emberData.default.attr('string'),
		fullName: _emberData.default.attr('string'),
		labelDescription: _emberData.default.attr('string'),
		mediumResImage: _emberData.default.attr('string'),
		mediumResImageToken: _emberData.default.attr('string'),
		productClassification: _emberData.default.attr('string'),
		informationProviderGln: _emberData.default.attr('string'),
		status: _emberData.default.attr('string'),
		gepirStatus: _emberData.default.attr('string'),
		netContent: _emberData.default.attr('string'),

		// Relationships
		netContentUnit: _emberData.default.belongsTo('net-content-unit'),
		member: _emberData.default.belongsTo('member', { inverse: 'eans' }),
		targetRegion: _emberData.default.belongsTo('region'),
		targetCountry: _emberData.default.belongsTo('country'),
		language: _emberData.default.belongsTo('language'),
		classificationBrick: _emberData.default.belongsTo('classification-brick'),
		dataProviderGln: _emberData.default.belongsTo('member'),
		tradeItemUnit: _emberData.default.belongsTo('trade-item-unit')
	});
});