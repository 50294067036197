define('gs1-frontend/helpers/truncate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.truncate = truncate;
	var helper = Ember.Helper.helper;
	function truncate(params) {
		var val = params[0];
		var max = params[1];
		var sufix = val && val.length > max ? '...' : '';
		return val ? val.substring(0, max) + sufix : '';
	}

	exports.default = helper(truncate);
});