define('gs1-frontend/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'gs1-frontend/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _oauth2PasswordGrant.default.extend({
		serverTokenEndpoint: _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/token',

		refreshAccessTokens: false
	});
});