define('gs1-frontend/pods/user/adapter', ['exports', 'gs1-frontend/pods/application/adapter', 'gs1-frontend/config/environment'], function (exports, _adapter, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return _environment.default.gs1.serverEndpoint + '/' + _environment.default.gs1.apiNamespace + '/me';
      }

      return this._super.apply(this, arguments);
    }
  });
});