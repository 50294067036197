define('gs1-frontend/pods/components/gtins/-edit-gtin/component', ['exports', 'gs1-frontend/pods/components/modals/-modal/component', 'gs1-frontend/mixins/errorable'], function (exports, _component, _errorable) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _component.default.extend(_errorable.default, {

		id: 'modalProductData',

		onCloseBound: false,

		formStatus: false,

		formStates: {
			dataProviderGln: false,
			classificationBrick: false,
			classificationClass: false,
			classificationFamily: false,
			classificationSegment: false,
			tradeItemUnit: false,
			language: false,
			targetCountry: false,
			fullName: false,
			brand: false,
			netContent: false,
			netContentUnit: false,
			mediumResImage: false
		},

		gtin13Array: [{ value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }, { value: 0, editable: false }],

		formStatesObserver: Ember.observer('formStatus', function () {
			var _this = this;

			// Update form only if status was '2' - Raspoloživ
			var self = this;
			var res = Object.keys(this.get('formStates'));
			res.forEach(function (key, index) {
				Ember.set(_this.get('formStates'), key, self.formStatus);
			});
		}),

		gtin13ControlDigit: Ember.computed('gtin13Array.@each.value', function () {
			return this.calculateGtin13ControlDigit(this.get('gtin13Array').mapBy('value'));
		}),

		calculateGtin13ControlDigit: function calculateGtin13ControlDigit(gtin13Arr) {
			var sum = 0,
			    controlDigit = '',
			    gtinValue = '';
			for (var i = 0; i < gtin13Arr.length; i++) {
				sum += (i % 2 ? 1 : 3) * parseInt(gtin13Arr.objectAt(gtin13Arr.length - 1 - i));
			}
			controlDigit = (10 - sum % 10) % 10;
			gtin13Arr.forEach(function (digit) {
				gtinValue += digit;
			});
			gtinValue += controlDigit;
			this.set('currentGtinValue', gtinValue);
			return '' + controlDigit ? controlDigit : 'x';
		},
		fillGtin13Array: function fillGtin13Array() {
			var self = this;

			var mandatoryLength = 14;
			var prefix = self.get('companyPrefix.prefixValue');
			var next = self.get('nextAvailableGtin');
			var lockedIndex = mandatoryLength;
			if (!prefix) {
				console.error('No prefix');
			} else if (!next) {
				console.error('No next');

				if (self.get('gtin.id')) {
					var value = self.get('gtin.gtinValue');
					self.get('gtin13Array').forEach(function (digit, index) {
						Ember.set(digit, 'value', value[index]);
						Ember.set(digit, 'editable', false);
					});
				}
			} else {
				var diff = mandatoryLength - next.length;
				for (var i = 0; i < diff; i++) {
					next = '0' + next;
				}
				lockedIndex = next.indexOf(prefix) + prefix.length;

				if (self.get('gtin.id')) {
					var value = self.get('gtin.gtinValue');
					self.get('gtin13Array').forEach(function (digit, index) {
						Ember.set(digit, 'value', value[index]);
						Ember.set(digit, 'editable', false);
					});
				} else {
					var newArray = [];
					for (var i = 0; i < mandatoryLength - 1; i++) {
						newArray.pushObject({
							value: next[i],
							editable: i >= lockedIndex
						});
						self.set('gtin13Array', newArray);
					}
				}
			}
		},


		formFields: Ember.computed(function () {
			return this.get('store').query('form-field', {
				'filter[field]': 'gtin,sale_country_id,brick_id,segment_id,class_id,family_id,brand_name,full_name,language,trade_item_unit_id,product_status_id,data_provider_gln,net_content_unit_id,net_content'
			});
		}),

		fieldHelpContent: Ember.computed('formFields.[]', function () {
			var arr = {};
			this.get('formFields').forEach(function (item, index) {
				arr[item.get('field')] = item.get('help_text');
			});
			return arr;
		}),

		allRegions: Ember.computed(function () {
			return this.get('store').findAll('region');
		}),

		netContentUnits: Ember.computed(function () {

			return this.get('store').findAll('net-content-unit');
		}),
		availableNetContentUnits: ['Gram', 'kilogram', 'Tona', 'Hektolitar', 'Decilitar', 'Mililitar', 'Litar', 'Mikrogradm', 'Miligram'],

		selectedUnit: '',

		selectedNetContentUnitsObserver: Ember.observer('selectedUnit', function () {
			if (this.get('selectedUnit') !== "null") {

				var unit = this.get('netContentUnits').findBy('id', this.get('selectedUnit'));
				if (unit) {
					this.get('gtin').set('netContentUnit', unit);
				} else {
					this.get('gtin').set('netContentUnit', null);
				}
			} else {
				this.get('gtin').set('netContentUnit', null);
			}
		}),

		selectedRegion: '',

		selectedRegionObserver: Ember.observer('selectedRegion', function () {
			if (this.get('selectedRegion') !== "null") {

				var region = this.get('allRegions').findBy('id', this.get('selectedRegion'));
				if (region) {
					this.get('gtin').set('targetRegion', region);
				} else {
					this.get('gtin').set('targetRegion', null);
				}
			} else {
				this.get('gtin').set('targetRegion', null);
			}
		}),

		allCountries: Ember.computed(function () {
			return this.get('store').findAll('country');
		}),

		selectedCountry: '',

		selectedCountryObserver: Ember.observer('selectedCountry', function () {
			if (this.get('selectedCountry') !== "null") {

				var country = this.get('allCountries').findBy('id', this.get('selectedCountry') ? this.get('selectedCountry').toString() : '');
				if (country) {
					this.get('gtin').set('targetCountry', country);
				} else {
					this.get('gtin').set('targetCountry', null);
				}
			} else {
				this.get('gtin').set('targetCountry', null);
			}
		}),

		allLanguages: Ember.computed(function () {
			return this.get('store').findAll('language');
		}),

		selectedLanguage: '',

		selectedLanguageObserver: Ember.observer('selectedLanguage', function () {
			if (this.get('selectedLanguage') !== "null") {

				var language = this.get('allLanguages').findBy('id', this.get('selectedLanguage'));
				if (language) {
					this.get('gtin').set('language', language);
				} else {
					this.get('gtin').set('language', null);
				}
			} else {
				this.get('gtin').set('language', null);
			}
		}),

		allTradeItemUnits: Ember.computed(function () {
			return this.get('store').findAll('trade-item-unit');
		}),

		selectedTradeItemUnit: '',

		selectedTradeItemUnitObserver: Ember.observer('selectedTradeItemUnit', function () {
			if (this.get('selectedTradeItemUnit') !== "null") {

				var unit = this.get('allTradeItemUnits').findBy('id', this.get('selectedTradeItemUnit'));
				if (unit) {
					this.get('gtin').set('tradeItemUnit', unit);
				} else {
					this.get('gtin').set('tradeItemUnit', null);
				}
			} else {
				this.get('gtin').set('tradeItemUnit', null);
			}
		}),

		availableTradeItemUnits: ['EA', // code - Osnovna jedinica
		'CS', // code - Zbirno pakovanje
		'MP'],

		allStatuses: Ember.computed(function () {
			return ['1', '2', '3', '4'];
		}),

		selectedStatus: '',

		selectedStatusObserver: Ember.observer('selectedStatus', function () {

			var status = this.get('selectedStatus');
			if (status) {
				this.get('gtin').set('status', status);
			} else {
				this.get('gtin').set('status', null);
			}
			if (this.get('startFormStatus')) {
				this.set('formStatus', status !== '1' ? true : false);
				this.notifyPropertyChange('formStatesObserver');
				this.set('startFormStatus', false);
			}
		}),

		allMembers: Ember.computed(function () {
			return this.get('store').query('member', { filter: { id: this.get('companyPrefix.member.id') } });
		}),

		selectedMember: '',

		selectedMemberObserver: Ember.observer('selectedMember', function () {
			if (this.get('selectedMember') !== "null") {

				var member = this.get('allMembers').findBy('id', this.get('selectedMember') ? this.get('selectedMember').toString() : '');
				if (member) {
					this.get('gtin').set('dataProviderGln', member);
					this.get('gtin').set('informationProviderGln', member.get('masterGlnNumber'));
				} else {
					this.get('gtin').set('dataProviderGln', null);
					this.get('gtin').set('informationProviderGln', null);
				}
			} else {
				this.get('gtin').set('dataProviderGln', null);
				this.get('gtin').set('informationProviderGln', null);
			}
		}),

		firstChange: {
			segment: true,
			family: true,
			class: true,
			brick: true
		},

		allClassificationSegments: Ember.computed(function () {
			return this.get('store').findAll('classification-segment');
		}),

		classificationSegment: '',

		segmentObserver: Ember.observer('classificationSegment', function () {

			if (this.get('classificationSegment') !== "null") {

				if (1 || !this.get('firstChange.segment')) {
					this.set('classificationFamily', '');
				} else {
					this.set('firstChange.segment', false);
				}
			} else {
				this.set('firstChange.segment', false);
			}
		}),

		allClassificationFamilies: Ember.computed('allClassificationSegments.[]', 'classificationSegment', function () {
			var segments = this.get('allClassificationSegments');
			if (segments.length) {
				var segment = segments.findBy('id', this.get('classificationSegment'));
				if (segment) {
					return segment.get('classificationFamilies');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		classificationFamily: '',

		familyObserver: Ember.observer('classificationFamily', function () {

			if (this.get('classificationFamily') !== "null") {

				if (1 || !this.get('firstChange.family')) {
					this.set('classificationClass', '');
				} else {
					this.set('firstChange.family', false);
				}
			} else {
				this.set('firstChange.family', false);
			}
		}),

		allClassificationClasses: Ember.computed('allClassificationFamilies.[]', 'classificationFamily', function () {
			var families = this.get('allClassificationFamilies');
			if (families.length) {
				var family = families.findBy('id', this.get('classificationFamily'));
				if (family) {
					return family.get('classificationClasses');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		classificationClass: '',

		classObserver: Ember.observer('classificationClass', function () {

			if (this.get('classificationClass') !== "null") {
				if (1 || !this.get('firstChange.class')) {
					this.set('classificationBrick', '');
				} else {
					this.set('firstChange.class', false);
					this.get('gtin').set('classificationBrick', null);
				}
			} else {
				this.set('firstChange.class', false);
				this.get('gtin').set('classificationBrick', null);
			}
		}),

		allClassificationBricks: Ember.computed('allClassificationClasses.[]', 'classificationClass', function () {
			var classes = this.get('allClassificationClasses');
			if (classes.length) {
				var theClass = classes.findBy('id', this.get('classificationClass'));
				if (theClass) {
					return theClass.get('classificationBricks');
				} else {
					return [];
				}
			} else {
				return [];
			}
		}),

		validateNum: '',
		validateNumber: Ember.observer('validateNum', function () {
			var value = this.get('gtin').get('netContent').toString();
			if (value.indexOf('.') === -1) {
				// if(value.slice(-1) === '.'){
				// 	return value.substr(0, value.length - 1)
				// }
				return value;
			}

			while ((value.slice(-1) === '0' || value.slice(-1) === '.') && value.indexOf('.') !== -1) {
				value = value.substr(0, value.length - 1);
			}
			var all = value.split(".", 2);
			var second = all[1].length > 3 ? all[1].substring(0, 3) : all[1];

			value = all[0] + '.' + second;

			this.get('gtin').set('netContent', value);
		}),
		classificationBrick: '',

		brickObserver: Ember.observer('classificationBrick', function () {
			if (this.get('classificationBrick') !== "null") {

				if (1 || !this.get('firstChange.brick')) {
					var brick = this.get('allClassificationBricks').findBy('id', this.get('classificationBrick'));
					if (brick) {
						this.get('gtin').set('classificationBrick', brick);
					} else {
						this.get('gtin').set('classificationBrick', null);
					}
				} else {
					this.set('firstChange.brick', false);
					this.get('gtin').set('classificationBrick', null);
					this.get('gtin').set('productClassification', null);
				}
			} else {
				this.set('firstChange.brick', false);
				this.get('gtin').set('classificationBrick', null);
				this.get('gtin').set('productClassification', null);
			}
		}),

		didInsertElement: function didInsertElement() {
			this._super.apply(this, arguments);
			var element = Ember.$(this.get('element'));

			element.on('click', function (e) {
				var target = Ember.$(e.target);
				var targetHelp = target.closest('.help');
				if (!targetHelp.length) {
					element.find('.help-content').hide();
				} else {
					if (target.closest('.question-mark').length) {
						targetHelp.find('.help-content').show();
					} else if (target.closest('.help-close').length) {
						targetHelp.find('.help-content').hide();
					}

					element.find('.help').not(targetHelp).find('.help-content').hide();
				}
			});
		},
		didReceiveAttrs: function didReceiveAttrs() {
			var currentMemberId = this.get('companyPrefix.member.id');
			if (currentMemberId) {
				if (this.get('gtin') && !this.get('gtin.id')) {
					this.set('selectedMember', currentMemberId);
					this.notifyPropertyChange('selectedMember');
				}
			}
		},
		didRender: function didRender() {
			var self = this;
			if (!self.get('onCloseBound')) {
				var onClose = self.get('onClose');
				if (typeof onClose === 'function') {
					Ember.$(self.get('element')).on('hide.bs.modal', onClose);
				}

				Ember.$(self.get('element')).on('hide.bs.modal', function () {
					self.resetErrors();
				});

				self.set('selectedRegion', '');
				self.set('selectedCountry', '');
				self.set('imageUploading', false);
				self.set('imageUploaded', false);
				self.set('onCloseBound', true);
			}

			if (!self.get('onShowBound')) {
				Ember.$(self.get('element')).on('show.bs.modal', function () {
					var gtin = self.get('gtin');
					if (gtin) {
						var brick, theClass, family, segment;

						gtin.get('classificationBrick').then(function (obtainedBrick) {
							if (obtainedBrick && obtainedBrick.get('id')) {
								brick = obtainedBrick;
								return obtainedBrick.get('classificationClass');
							}
						}).then(function (obtainedClass) {
							if (obtainedClass && obtainedClass.get('id')) {
								theClass = obtainedClass;
								return obtainedClass.get('classificationFamily');
							}
						}).then(function (obtainedFamily) {
							if (obtainedFamily && obtainedFamily.get('id')) {
								family = obtainedFamily;
								return obtainedFamily.get('classificationSegment');
							}
						}).then(function (obtainedSegment) {
							if (obtainedSegment && obtainedSegment.get('id')) {
								segment = obtainedSegment;
							}
						}).finally(function () {
							self.set('classificationSegment', segment ? segment.get('id') : '');
							self.set('classificationFamily', segment && family ? family.get('id') : '');
							self.set('classificationClass', segment && family && theClass ? theClass.get('id') : '');
							self.set('classificationBrick', segment && family && theClass && brick ? brick.get('id') : '');
						});

						var language;
						gtin.get('language').then(function (obtainedLanguage) {
							if (obtainedLanguage && obtainedLanguage.get('id')) {
								language = obtainedLanguage;
							} else {
								language = self.get('allLanguages').filterBy('isDefault', true).objectAt(0);
							}
						}).finally(function () {
							self.set('selectedLanguage', language ? language.get('id') : '');
							self.notifyPropertyChange('selectedLanguage');
						});

						// gtin.get('country')
						// 	.then((obtainedCountry) => {
						// 	if (obtainedCountry && obtainedCountry.get('id')) {
						// 		country = obtainedCountry;
						// 	}
						// 	else {
						// 		country = '264';
						// 	}
						// })
						// .finally(() => {
						// 	self.set('selectedCountry', country ? country.get('id') : '');
						// 	self.notifyPropertyChange('selectedCountry');
						// });

						var sCountry;
						gtin.get('targetCountry').then(function (obtainedCountry) {
							if (obtainedCountry && obtainedCountry.get('id')) {
								sCountry = obtainedCountry;
							}
						}).finally(function () {
							self.set('selectedCountry', sCountry ? sCountry.get('id') : self.get('gtin').get('id') === null ? '264' : null);
							self.notifyPropertyChange('selectedCountry');
						});

						var region;
						gtin.get('targetRegion').then(function (obtainedRegion) {
							if (obtainedRegion && obtainedRegion.get('id')) {
								region = obtainedRegion;
							} else {
								region = self.get('allRegions').filterBy('isDefault', true).objectAt(0);
							}
						}).finally(function () {
							self.set('selectedRegion', region ? region.get('id') : '');
							self.notifyPropertyChange('selectedRegion');
						});

						var status = gtin.get('status');

						self.set('formStatus', status === '2' ? true : false);
						self.notifyPropertyChange('formStatus');

						self.set('selectedStatus', status ? status : '1');
						self.notifyPropertyChange('selectedStatus');

						//self.gtin13ArrayObserver();
						self.fillGtin13Array();
					}
				});

				self.set('onShowBound', true);
			}

			if (!self.get('focusesBound')) {
				var inputs = Ember.$(self.get('element')).find('.gtin-digits-table input');
				inputs.on('focus', function () {
					Ember.$(this).select();
				});
				inputs.on('change', function () {
					console.log(Ember.$(this).val());
				});

				self.set('focusesBound', true);
			}

			Ember.$(self.get('element')).find('#edit-gtin-image-input').fileTransport({
				start: function start() {
					self.set('imageUploading', true);
					self.set('imageUploaded', false);
					self.set('imageError', null);
				},
				success: function success(event, file, token, meta, data) {
					self.set('imageUploading', false);
					self.set('imageUploaded', true);
					self.set('imageError', null);
					self.set('gtin.mediumResImage', meta.thumbnail);
					self.set('gtin.mediumResImageToken', token);
					/*
     $('[data-gallery-list]').prepend('<div class="column">' +
     	'<a href="javascript:void(0);" class="gallery-image">' +
     	'<img src="' + meta.thumbnail + '&w=400&h=300" class="added-image" />' +
     	'<img src="/img/gallery_placeholder.jpg" style="opacity: 0.5" />' +
     	'<input name="data[gallery][]" type="hidden" value="' + token + '" />' +
     	'</a>' +
     	'</div>');
     */
				},
				error: function error(event, id, message) {
					self.set('imageError', message);
					self.set('imageUploading', false);
					self.set('imageUploaded', false);
				}
			});
		},


		actions: {
			digitClicked: function digitClicked() {
				this.$().find('.gtin-digits-table input:focus').select();
			},
			keyPressed: function keyPressed() {
				var allowed = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
				var input = this.$().find('.gtin-digits-table input:focus');
				if (input.val().length > 1) {
					input.val(input.val().substr(0, 1));
				}
				if (!allowed.includes(input.val())) {
					input.val('0');
				}
			},
			keyUp: function keyUp() {
				var allowed = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
				var input = this.$().find('.gtin-digits-table input:focus');
				if (input.val().length > 1) {
					input.val(input.val().substr(0, 1));
				}
				if (!allowed.includes(input.val())) {
					input.val('0');
				}
				input.closest('td').next('td').find('input').focus();
			},
			removeImage: function removeImage() {
				this.set('gtin.mediumResImageToken', null);
				this.set('gtin.mediumResImage', null);
			},
			saveGtin: function saveGtin() {
				var self = this;

				self.set('gtin.gtinValue', self.get('currentGtinValue'));

				// Increment gtin counter
				if (self.get('gtin.id') === null) {
					self.set('companyPrefix.gtinCount', self.get('companyPrefix.gtinCount') + 1);
				}
				var onSave = self.get('onSave');
				if (typeof onSave === 'function') {
					onSave().then(function () {
						self.closeModal();
					}).catch(function (response) {
						self.handleErrors(response.errors);
					}).finally(function () {
						//self.closeModal();
					});
				}
			}
		}

	});
});